<template>
  <Header />
  <div class="main-container">
    <Sidebar />
    <main class="content">
      <div class="content-section">
        <CardHistrorique />
      </div>
    </main>
  </div>
</template>
  
<script>

import Header from '../../components/Header.vue';
import Sidebar from '../../components/Sidebar.vue';
import CardHistrorique from '../../components/CardHistorique.vue';

export default {
  components: {
    CardHistrorique,
    Header,
    Sidebar,
  },
}
</script>
  
<style scoped>
.main-container {
  display: flex;
}

.content {
  flex: 1;
  /* padding: 20px; */
}

@media (max-width: 768px) {
  .main-container {
    flex-direction: column;
  }

  .content {
    padding: 20px;
  }
}

.content-section {
  min-height: 90vh;
}
</style>