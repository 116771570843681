<template>
    
    <SidebarHeader />
    <MissionAttribuer />
  
  </template>
  
  <script>
  
  import SidebarHeader from '../../components/SidebarHeader.vue';
  import MissionAttribuer from '../../components/MissionAttribuer.vue';
  
  export default {
    components: {
      SidebarHeader,
      MissionAttribuer,
  
    },}
  </script>
  
  <style>
  
  </style>