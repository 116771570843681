import { createStore } from 'vuex';
import axios from 'axios';
import createPersistedState from 'vuex-persistedstate';

const store = createStore({
  state () {
    return {
      admin: {},
      consultant: {}
    }
  },
  getters: {
    getadmin (state) {
      return state.admin
    },
    getconsultant (state) {
      return state.consultant
    },
    isAdminLoggedIn(state) {
      return !!state.admin.token;
    },
    isConsultantLoggedIn(state) {
      return !!state.consultant.token;
    },
  },
  mutations: {
    SET_ADMIN(state, payload) {
      state.admin = payload  
    },
    SET_CONSULTANT(state, payload) {
      state.consultant = payload  
    },
    SET_ADMIN_TOKEN(state, token) {
      localStorage.setItem('admin_token', token);
      localStorage.removeItem('consultant_token');
      // setTimeout(() => {
      //   localStorage.removeItem('admin_token');
      // }, 180000);  
    },
    SET_CONSULTANT_TOKEN(state, token) {
      localStorage.setItem('consultant_token', token);
      localStorage.removeItem('admin_token');
      // setTimeout(() => {
      //   localStorage.removeItem('consultant_token');
      // }, 180000);  
    }
  },
  actions: {
    async loginAdmin({ commit }, payload) {
      try {
        const response = await axios.post('http://missions-freelance-industrie.fr/admin/login', payload);
        if (response.data && response.data.token) {
          commit('SET_ADMIN_TOKEN', response.data.token);
          commit('SET_ADMIN', response.data.admin);
          return true;
        } else {
          console.error(response.data.error);
          return false;
        }
      } catch (error) {
        console.error('Login failed', error);
        throw error;
      }
    },
    async loginConsultant({ commit }, payload) {
        try {
          const response = await axios.post('http://missions-freelance-industrie.fr/consultant/login', payload);
          if (response.data && response.data.token) {
            commit('SET_CONSULTANT_TOKEN', response.data.token);
            commit('SET_CONSULTANT', response.data.consultant);
            return true;
          } else {
            console.error(response.data.error);
            return false;
          }
        } catch (error) {
          console.error('Consultant login failed', error);
          throw error;
        }
      }
  },
  plugins: [createPersistedState({
    paths: ['admin', 'consultant'] 
  })]
});

export default store;
