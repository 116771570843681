import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@fortawesome/fontawesome-free/css/all.css'

import './assets/main.css'

import VCalendar from 'v-calendar';


const app = createApp(App);

app.use(VCalendar);
app.use(router);
app.use(store);
app.mount('#app');

/*new Vue({ Vuetify,render: h => h(App),
}).$mount('#app')*/