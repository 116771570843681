<template>
    
        <Navbar />
        <Postuler />
        <Footer />
    
</template>

<script>

    import Navbar from '../../components/Pages-annonces/Navbar.vue';
    import Postuler from '../../components/Postule.vue';
    import Footer from '../../components/Footer.vue';


    /*export default {
        components:'SidebarHeader',
        name: 'Postuler',// Make sure this matches the name in the route configuration
  // Other component options here
};*/
    export default {
    components: {
        Navbar,
        Postuler,
        Footer,

    },}
</script>

<style scoped>
@media (min-width: 768px){
header{
    /* width: 80% !important; */
    position: relative;
    z-index: 125;
    background: rgb(255 255 255 / 72%);
    display: flex;
    justify-content: center;
}
}
</style>