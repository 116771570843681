<template>
    <div class="notfound">
        <div class="text">
            <h1>Oops!</h1>
            <h2>404 | Page introuvable</h2>
            <p>
                retourner à la <a class="link" href="/">Page d'accueil</a>?                
            </p>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'NotFound'
}
</script>

<style scoped>
.notfound {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #000000;
}

.notfound .text {
    text-align: left;
}

.notfound h1 {
    font-family: montserrat, sans-serif;
    font-size: 10em !important;

}
.notfound h2 {
    font-size: 2.5em !important;
}
.notfound p {
    line-height: 2em;
    font-size: 1.4em;
}
.notfound a {
    color: #484b4d;
}

.link {
	position: relative;
	font-weight: bold;
	
	&::before {
		content: '';
		background-color: #f5ff00;
		position: absolute;
		left: .5rem;
		bottom: 5px;
		width: 100%;
		height: 8px;
		z-index: -1;
		transition: all .3s ease-in-out;
	}
	
	&:hover::before {
		left: -5px;
		bottom: 0;
		height: 100%;
		width: calc(100% + 10px);
	}	
}

</style>

