<template>
  <div class="container">
    <div class="rectangle-login">
      <p class="dashboard">
        <router-link v-if="isAdminLoggedIn" class="link" to="/dashboard"><i class="fa-solid fa-rotate-left"></i> Dashboard</router-link>
      </p>
      <img src="../assets/smartphone.png" alt="" srcset="">
      

      <h1>Login Consultant</h1>

      <span>Connectez-Vous a votre compte</span>

      <div class="inputs">
        <label>Email</label>
        <input type="Email" v-model="email_consultant" name="email" id="email" class="inpt1"
          placeholder="Entrez Votre Email">
        <label>Mot De Passe</label>
        <input type="password" v-model="password_consultant" name="password" id="password" class="inpt1"
          placeholder="********">
        <div class="forgot-password">
          <a href="#" class="a1">Mot de passe oublié ?</a>
          <a href="#" class="a2">Réinitialiser</a>
        </div>
        <button @click="handleLogin" class="cnx">Connecter</button>
      </div>
      <p class="signup-link">Vous n’avez pas de compte ? <a href="#">S’inscrir maintenant</a></p>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      email_consultant: '',
      password_consultant: ''
    };
  },
  methods: {
    async handleLogin() {
      try {
        const isLogged = await this.$store.dispatch('loginConsultant', {
          email_consultant: this.email_consultant,
          password_consultant: this.password_consultant
        });

        if (isLogged) {
          this.$router.push('/missionconsultant');
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erreur!',
            text: 'La connexion a échoué. Veuillez vérifier vos identifiants.',
            confirmButtonText: 'OK'
          });
        }

      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Erreur!',
          text: 'Une erreur est survenue lors de la connexion. Veuillez réessayer ultérieurement.',
          confirmButtonText: 'OK'
        });
      }
    },
    getLink() {
      if (this.isAdminLoggedIn) {
        return 'Dashboard';
      }
    }
  },
  computed: {
    isAdminLoggedIn() {
      const adminToken = localStorage.getItem('admin_token');
      return !!adminToken;
    },
  }
}
</script>

<style scoped>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-size: 14px;
  background-color: #F6F1F1;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rectangle-login {
  width: 400px;
  padding: 20px;
  border-radius: 15px;
  border: 1px solid #DFDFDF;
  background: #FFF;
  box-shadow: 1px 1px 9px 0px rgba(132, 132, 132, 0.25);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.rectangle-login img {}

.rectangle-login .dashboard {
  text-align: right;
}
.dashboard .link i {
  font-size: 1.2em;
}
.dashboard .link {
  font-size: 1.1em;
  padding: 15px 10px;
  color: #000;
  transition: all 0.2s ease-out;
  border-radius: 10px;
  border: 1px solid rgba(20, 108, 148, 0.95);
  display: inline;
}
.dashboard .link:hover {
  border: 1px solid rgba(20, 108, 148, 0.95);
  color: #FFF;
  background-color:  rgba(0, 108, 148, 0.95) !important;
  transition: all 0.2s ease-in;
}
.rectangle-login h1 {
  color: #000;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-right: 270px;
  margin-top: 10px;
}

.rectangle-login p {
  color: #595959;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 8px;
}




span {
  color: #595959;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: block;
  text-align: center;
  margin: 15px 0 20px 0;
}

.inputs {}

.inputs label {
  color: #595959;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 15px;
}

.inputs .inpt1 {
  width: 100%;
  height: 30px;
  border-radius: 8px;
  border: 1px solid rgba(20, 108, 148, 0.95);
  background: #FFF;
  margin: 15px 0;
  text-indent: 10px;
}

.inpt1::placeholder {
  color: #B8B8B8;
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-indent: 10px;
}

.forgot-password {
  display: flex;
  justify-content: space-between;
}

.forgot-password .a1 {
  text-align: center;
}

.forgot-password .a2 {
  text-align: center;
}

.forgot-password .a1 {
  color: #999999;
  text-decoration: none;
}

.forgot-password .a1:hover {
  text-decoration: underline;
}

.forgot-password .a2 {
  color: rgba(20, 108, 148, 0.95);
  text-decoration: none;
}

.forgot-password .a2:hover {
  text-decoration: underline;
}

.cnx {
  width: 100%;
  margin: 30px 0 15px 0;
  background-color: rgba(20, 108, 148, 0.95);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;

}

.cnx:hover {
  background-color: darkblue;
}

.signup-link {
  text-align: center;
  color: #999999;
  text-decoration: none;
  font-size: 10px;
}

.signup-link a:hover {
  text-decoration: underline !important;
  color: #000;
}

@media only screen and (max-width: 600px) {
  .rectangle-login {
    width: 350px;
  }
}
</style>
