<template>
  <Header />
  <div class="main-container">
    <main class="content">
      <div class="content-section">
        <Dashboard />
      </div>
    </main>
  </div>
</template>

<script>

import Header from '../components/Header.vue';
import Dashboard from '../components/Dashboard.vue';

export default {
  components: {
    Dashboard,
    Header,
  },
}
</script>

<style scoped>
.main-container {
  display: flex;
}

.content {
  flex: 1;
  /* padding: 20px; */
}



.content-section {
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
@media (max-width: 540px) {
  /* .main-container {
    flex-direction: column;
  } */

  .content {
    padding: 0;
  }

  .content-section {
    margin: 0;
    padding: 0;
  }
}
</style>