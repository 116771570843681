<template>
    <Navbar />
    <h1>Détails de mission en cours</h1>
    <div class="rectangle-container">
        <div class="rectangle">
            <div class="label">Ingénieur Planning / OPC</div>
            <p style="margin-left:-146px;">Ref : 2407230001</p>
            <div class="options">
                <a href="#">Publié le : 24/06/2023 </a>
                <a href="#">Marrakech, Maroc</a>
                <a href="#">Durée : 6 mois</a>
                <a href="#">Démarage : 07/07/2023</a>
            </div>
            <h3 class="custom-h3">Compétences requises</h3>
            <p class="custom-p">AZURE, MICROSOFT, MICROSOFT, DYNAMICS 365, Anglais</p>
            <h3 class="custom-h3-2">This is a new H3</h3>
            <p class="custom-p-2">This is a new paragraph.</p>
            <h3 class="custom-h3-3">Description de la mission</h3>
            <p class="custom-p-3">
                This is a new paragraph.
            </p>
        </div>
        <div class="rectangle" style="height:480px;">
            <div class="inputs">
                <h3 style="margin-right: 300px;margin-top:20px;">Consultant</h3>
                <h4 style="margin-top:30px;">Nom</h4>
                <input type="text" name="text" id="text" class="inpt1" placeholder="Entrez Votre Nom">
                <h4 style="margin-top:60px;">Prénom</h4>
                <input type="email" name="email" id="email" class="inpt1" placeholder="Entrez Votre Prénom">
                <h4 style="margin-top:60px;">Email</h4>
                <input type="text" name="email" id="email" class="inpt1" placeholder="Entrez Votre Email"> 
                <h4 style="margin-top:60px;">CV</h4>
                <a href="#" style="color: #146C94;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                float: left;
                margin-left: 30px;
                margin-top:10px;">Mehdi.berrada.pdf</a>
            </div> 
        </div>
    </div>
</template>

<script>
import Navbar from '../../components/Pages-admine/Navbar.vue'

export default {
    components: {
    Navbar,
  },
}
</script>

<style scoped>
h1{
    position: absolute; /* Added */
    top: 80px; /* Adjust this to move h1 up or down */
    color: #000;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    width: 389px;
    height: 49px;
    flex-direction: column;
    justify-content: center;
    margin-left: 130px;
}

.label{
    margin-bottom: 10px;
    font-weight: bold;
    float: left;
    margin-top: 20px;
    margin-left: 20px;
    text-transform: uppercase;
    color: #000;
    font-weight: 600;
}
.rectangle-container{
    display: flex;
}

.rectangle{
    width: 441px;
    height: 577px;
    margin-right: 60px; /* Added */
    border-radius: 10px;
    border: 1px solid #E9E0E0;
    background: #FFF;
    box-shadow: 0px 4px 8px -3px rgba(0, 0, 0, 0.25);
}
.inputs {
  position: relative;
}
.inputs h4{
  color: #595959;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 50px;
  margin-left: -70px;
}
.inputs .inpt1{
  float: left;
  width: 352px;
  height: 30px;
  border-radius: 0px;
  border: none; /* Remove any existing border */
  border-bottom: 1px solid black;
  background: #FFF;
  margin-top: 10px;
  margin-left: 20px;
}
.inpt1::placeholder {
  color: #B8B8B8;
  font-family: Inter;
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-indent: 20px;
}
.rectangle{
    position: relative;

}
.options {
    display: flex;       /* Flexbox for aligning items in a row */
    justify-content: space-around; /* Even spacing between items */
    position: absolute; /* Positions the .options div at the bottom of the .rectangle */
    bottom: 450px;          /* Distance from the bottom of the .rectangle */
    width: 100%;        /* Full width of the .rectangle */
    padding: 10px 0;    /* Some padding top and bottom for appearance */
}

.options a {
    text-decoration: none; /* Removes the default link underline */
    color: black;       /* Sets the link color to black */
    font-size: 8px;
}
.custom-h3 {
    /* Your H3 styles here */
    float: left;
    margin-left: 20px;
    font-size: 18px; /* Example */
    margin-top: 70px;
}
.custom-p {
    position: absolute;
    bottom: 370px;
    right: 80px;
}
.custom-h3-2 {
    position: absolute;
    top: 220px;
    right: 290px;
}

.custom-p-2 {
    /* Your second paragraph styles here */
    position: absolute;
    bottom: 300px; /* Adjust as per requirement */
    right: 160px;
}
.custom-h3-3{
    position: absolute;
    top: 300px;
    left: 20px;
}
.custom-p-3 {
    /* Your second paragraph styles here */
    position: absolute;
    bottom: 230px; /* Adjust as per requirement */
    right: 160px;
}
</style>
