<template>
    <div :class="card_title" v-for="card in cards" :key="card.title" class="cardavalider box">
        <div class="card_body">
            <div class="card_content">
                <h2 class="card_title">{{ card.title }}</h2>
                <div class="card_description">
                    <p>{{ card.description }}</p>
                </div>
                <div class="card_summary">
                    <a><i class="fa-regular fa-clock"></i> Publié le : {{ card.date }}</a>
                    <a><i class="fa-solid fa-location-dot"></i> {{ card.location }}</a>
                    <a><i class="fa-solid fa-hourglass-start"></i> Durée : {{ card.duration }}</a>
                    <a><i class="fa-regular fa-calendar-days"></i> Démarage : {{ card.startDate }}</a>
                    <a><i class="fa-solid fa-hand-holding-dollar"></i>Tarif  : {{ card.tarif_mission }} €</a>
                </div>
            </div>
            <div class="card_actions">
                <div class="check">
                    <a @click="checkCard(card)"><i class="fa-solid fa-circle-info"></i></a>
                </div>
                <!--
                <div class="edit">
                    <a @click="editCard(card)"><i class="fa-solid fa-file-pen"></i></a>
                </div>
                -->
                
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            cards: [
                {
                    title: "Ingénieur Planning / OPC",
                    description: "Nous recherchons un développeur Sénior Full Stack pour une mission de 3 mois renouvelables horizon longue durée sur Paris Intramuros. Intégré(e) au sein d’une équipe",
                    date: "24/06/2023",
                    location: "Marrakech, Maroc",
                    duration: "6 mois",
                    startDate: "07/07/2023",
                },
                // Add more card objects as needed
            ],
        };
    },
    methods: {
        checkCard(card) {
            // Implement logic to handle accepting the card
        },
        editCard(card) {
            // Implement logic to handle not accepting the card
        },
    },
    mounted() {
        // Your mounted code goes here (if needed)
    },
};
</script>

<style scoped>
.cardavalider {
    padding: 30px 20px;
    border-radius: 10px;
    margin: 15px 0;
}

.card_body {
    display: flex;
    align-items: center;
}

.card_title {
    font-weight: bold;
}

.card_summary {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
}


.card_summary svg {
    font-size: 25px;
}

.card_content {
    width: 95%;
}

.card_actions {
    width: 5%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    height: 100px;
}

.card_actions svg {
    font-size: 30px;
}

.card_actions .check a {
    color: #146C94;
}

.card_actions .edit a {
    color: #146C94;
}

.form-body h1 {
    text-align: center;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: center;

}

.box {
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
}

.box:hover {
    transform: translateY(-5px);
    box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
    transition: 0.3s;
}

@media only screen and (max-width: 750px) {

    .container {
        margin-top: 10% !important;
        flex-direction: column-reverse;
        display: flex;
        align-items: center;
    }

    .container .sidebar {
        width: 100%;
        padding: 30px 0px;
        text-align: center;
    }

    .row {
        margin: 0;
        flex-direction: column;
    }

    .card_body {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .card_actions {
        width: 50%;
        display: flex;
        margin-top: 20px;
        flex-direction: row;
        justify-content: space-around;
    }

    .card_actions svg {
        font-size: 40px;
    }

    .card_body .card_summary {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding: 5px 0;
    }

    .card_summary a {
        margin: 5px 0;
    }
}
</style>