<template>
    <div>
      <!-- HEADER -->
      <header class="header">
        <div class="header__container">
          <img src="" alt="" class="header__img">
          <a href="#" class="header__logo">Logo</a>
          <div class="header__nav">
            <router-link to="/dashboard">Dashboard</router-link>
            <router-link to="/">Annonces</router-link>
          </div>
          <div class="header__toggle" @click="toggleNavbar">
            <i class="bx bx-menu" :class="{ 'bx-x': isNavbarOpen }"></i>
          </div>
        </div>
      </header>
  
      <!-- NAV -->
      <div class="nav" :class="{ 'show-menu': isNavbarOpen }" id="navbar">
        <nav class="nav__container">
          <div>
            <a href="#" class="nav__link nav__logo">
              <i class="bx bxs-disc nav__icon"></i>
              <span class="nav__logo-name">Logo</span>
            </a>
  
            <div class="nav__list">
              <div class="nav__items">
                <h3 class="nav__subtitle">Menu</h3>
  
                <a href="#" class="nav__link active">
                  <i class="bx bx-home nav__icon"></i>
                  <span class="nav__name">Liste des consultants</span>
                </a>
  
                <a href="#" class="nav__link">
                  <i class="bx bx-message-rounded nav__icon"></i>
                  <span class="nav__name">Liste des missions à valider</span>
                </a>
  
                <a href="#" class="nav__link">
                  <i class="bx bx-message-rounded nav__icon"></i>
                  <span class="nav__name">Liste des missions validées</span>
                </a>
  
                <a href="#" class="nav__link">
                  <i class="bx bx-message-rounded nav__icon"></i>
                  <span class="nav__name">Historuque des missions</span>
                </a>
              </div>
            </div>
          </div>
  
          <a href="#" class="nav__link nav__logout">
            <i class="bx bx-log-out nav__icon"></i>
            <span class="nav__name">Déconnecter</span>
          </a>
        </nav>
      </div>


  
      
    </div>
  </template>
  
  <script>
  
  export default {
    data() {
      return {
        isNavbarOpen: false
      };
    },
    methods: {
      toggleNavbar() {
        this.isNavbarOpen = !this.isNavbarOpen;
      }
    }
  };

  
  </script>
  
  <style scoped>
  /*========== CSS ==========*/
  @media screen and (min-width: 1024px) {
    /* ... */
  }
  
  /*========== BASE ==========*/
  *, ::before, ::after {
    box-sizing: border-box;
  }
  /*
  body {
    margin: 60px 0 0 0;
    padding: 1rem 1rem 0;
    font-family: 13;
    font-size: 14px;
    background-color: #F6F1F1;
    color: #000;
  }*/
  
  h3 {
    margin: 0;
  }
  
  a {
    text-decoration: none;
  }
  
  img {
    max-width: 100%;
    height: auto;
  }
  
  /*========== HEADER ==========*/
  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #EDE8E8;
    box-shadow: 0 1px 0 rgba(22, 8, 43, 0.1);
    padding: 0 1rem;
    z-index: 100;
  }
  
  .header__container {
    display: flex;
    align-items: center;
    height: 60px;
    justify-content: space-between;
  }
  
  .header__img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }
  
  .header__logo {
    color: #222;
    font-weight: 600;
    display: none;
  }
  
  .header__nav {
    display: flex;
    justify-content: space-evenly;
  }
  
  .header__nav a {
    margin: 0 10px;
    font-size: 17px;
    color: #000;
  }
  
  .header__nav a:hover {
    color: rgb(121, 121, 121);
    transition: 0.5s;
  }
  
  .header__icon, 
  .header__toggle {
    font-size: 1.2rem;
  }
  
  .header__toggle {
    color: #A5A1AA;
    cursor: pointer;
  }
  
  /*========== NAV ==========*/
  .nav {
    position: fixed;
    top: 0;
    left: -100%;
    height: 100vh;
    padding: 1rem 1rem 0;
    background-color: #222;
    box-shadow: 1px 0 0 rgba(22, 8, 43, 0.1);
    z-index: 100;
    transition: .4s;
  }
  
  .nav__container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 3rem;
    overflow: auto;
    scrollbar-width: none; /* For mozilla */
  }
  
  /* For Google Chrome and others */
  .nav__container::-webkit-scrollbar {
    display: none;
  }
  
  .nav__logo {
    font-weight: var(--font-semi-bold);
    margin-bottom: 2.5rem;
  }
  
  .nav__list, 
  .nav__items {
    display: grid;
  }
  
  .nav__list {
    row-gap: 2.5rem;
  }
  
  .nav__items {
    row-gap: 1.5rem;
  }
  
  .nav__subtitle {
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: .1rem;
    color: #F6F1F1;
  }
  
  .nav__link {
    display: flex;
    align-items: center;
    color: #A5A1AA;
  }
  
  .nav__link:hover {
    color: #fff;
  }
  
  .nav__icon {
    font-size: 1.2rem;
    margin-right: .5rem;
  }
  
  .nav__name {
    font-size: 14px;
    font-weight: 400;
    white-space: nowrap;
  }
  
  .nav__logout {
    margin-top: 5rem;
  }
  
  /*===== Show menu =====*/
  .show-menu {
    left: 0;
  }
  
  /*===== Active link =====*/
  .active {
    color: #fff;
  }
  
  /* ========== MEDIA QUERIES ==========*/
  /* For small devices reduce search*/
  
  @media screen and (max-width: 320px) {
    .header__nav {
      width: 70%;
    }
  }
  
  @media screen and (min-width: 768px) {
    body {
      padding: 20px 40px 0 80px;
    }
    .header {
      padding: 0 40px 0 80px;
    }
    .header__container {
      height: 60px;
    }
    .header__nav {
      width: 300px;
      padding: .55rem .75rem;
    }
    .header__toggle {
      display: none;
    }
    .header__logo {
      display: block;
    }
    .header__img {
      width: 40px;
      height: 40px;
      order: 1;
    }
    .nav {
      left: 0;
      padding: 1.2rem 1.5rem 0;
      width: 68px; /* Reduced navbar */
    }
    .nav__items {
      row-gap: 1.7rem;
    }
    .nav__icon {
      font-size: 1.3rem;
    }
  
    /* Element opacity */
    .nav__logo-name, 
    .nav__name, 
    .nav__subtitle, 
    .nav__dropdown-icon {
      opacity: 0;
      transition: .3s;
    }
  
    /* Navbar expanded */
    .nav:hover {
      width: 300px;
    }
  
    /* Visible elements */
    .nav:hover .nav__logo-name {
      opacity: 1;
    }
    .nav:hover .nav__subtitle {
      opacity: 1;
    }
    .nav:hover .nav__name {
      opacity: 1;
    }
    .nav:hover .nav__dropdown-icon {
      opacity: 1;
    }
  }
  </style>
  