<template>
    <div>
        <a @click="toggleSidebar" class="open-sidebar-button">
            <i class="fa-solid fa-bars"></i>
        </a>
        <div class="sidebar" :class="{ 'is-open': isSidebarOpen }">
            <router-link to="/listeconsultant" class="a1"><i class="fa-solid fa-clipboard-user"></i> Liste des
                consultants</router-link>
            <router-link to="/AjouterMission" class="a2"><i class="fa-solid fa-file-circle-plus"></i> Ajouter une
                Mission</router-link>
            <router-link to="/MissionsValidees" class="a3"><i class="fa-solid fa-file-circle-check"></i> Liste des missions
                validées</router-link>
            <router-link to="/MissionsAttribuees" class="a4"><i class="fa-solid fa-file-arrow-up"></i> Liste des missions
                attribuées</router-link>
            <router-link to="/MissionsValider" class="a5"><i class="fa-solid fa-file-circle-exclamation"></i> Liste des
                missions à
                valider</router-link>
            <router-link to="/HistoriqueMissions" class="a6"><i class="fa-solid fa-file-lines"></i> Historique des
                missions</router-link>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'Sidebar',

    data() {
        return {
            isSidebarOpen: false,
        };
    },
    methods: {

        toggleSidebar() {
            this.isSidebarOpen = !this.isSidebarOpen;
            this.$emit('sidebar-opened', this.isSidebarOpen);
        },
    },
}
</script>
  
<style scoped>
.sidebar {
    width: 250px;
    height: 100%;
    transition: transform 0.3s;
    background: #146c94;
    display: flex;
    flex-direction: column;
}

.sidebar .a1 {
    position: fixed;
    top: 120px
}

.sidebar .a2 {
    position: fixed;
    top: 180px
}

.sidebar .a3 {
    position: fixed;
    top: 240px
}

.sidebar .a4 {
    position: fixed;
    top: 300px
}

.sidebar .a5 {
    position: fixed;
    top: 360px
}

.sidebar .a6 {
    position: fixed;
    top: 420px
}

.sidebar a i {
    font-size: 25px;
    width: 30px;
}

.sidebar a {
    text-decoration: none !important;
    color: #fff;
    padding: 15px 15px;
    width: 250px;
}

.sidebar a:hover {
    background: #19A7CE;
    transition: 0.3s;
}

.is-open {
    transform: translateX(0);
}

.open-sidebar-button {
    display: none;
    position: absolute;
    top: 0;
    margin: 24px 10px;
    padding: 10px;
    color: white;
    transition: all 0.2s ease-out;
}

/* .sidebar {
    position: fixed;
    left: 0;
    top: 0;
    width: 250px;
    height: 100%;
    background-color: #f0f0f0;
} */

@media screen and (max-width: 850px) {
    .sidebar {
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
        overflow-y: auto;
        display: none;
        transition: all 0.2s ease-out;
    }

    .sidebar .a1 {
        position: initial;
        top: none
    }

    .sidebar .a2 {
        position: initial;
        top: none
    }

    .sidebar .a3 {
        position: initial;
        top: none
    }

    .sidebar .a4 {
        position: initial;
        top: none
    }

    .sidebar .a5 {
        position: initial;
        top: none
    }

    .sidebar .a6 {
        position: initial;
        top: none
    }

    .open-sidebar-button {
        display: initial;
    }

    .is-open {
        display: block;
        position: absolute;
        top: auto;
        height: auto;
        display: flex;
        transition: transform 0.3s;
        -webkit-animation: fpFadeInDown 300ms ease-in-out;
        animation: fpFadeInDown 300ms ease-in-out;
    }
}</style>
  