<template>
  <div class="container">
    <div class="title">
      <h1 class="page_title">Mes missions</h1>
      <br>
      <h2 class="sub_page_title">Details de mission courante</h2>
    </div>
    <div class="in__container">
      <div class="form">
        <div class="form-body">
          <div class="cardavalider box">
            <div class="card_body">
              <div class="card_content">
                <h2 class="card_title">{{ mission.nom_mission }}</h2>
                <div class="card_summary">
                  <a><i class="fa-regular fa-clock"></i> Publié le : {{ formatMissionDate(mission.created_at) }} </a>
                  <a><i class="fa-solid fa-map-pin"></i> {{ mission.ville }}</a>
                  <a><i class="fa-solid fa-hourglass-start"></i> Démarrage : {{ formatMissionDate(mission.date_debut_ms)
                  }}</a>
                  <a><i class="fa-solid fa-hourglass-end"></i> Fin : {{ formatMissionDate(mission.date_fin) }}</a>
                  <a><i class="fa-regular fa-calendar-days"></i> Durée : {{ mission.duree }} mois</a>
                </div>
                <div class="desc">
                  <div class="competences">
                    <h3>Compétences requises</h3>
                    <p>A{{ mission.competences_requises }}</p>
                  </div>
                  <div class="description_mission">
                    <h3>Description</h3>
                    <p>
                      {{ mission.description_mission }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="cardavalider box">
            <table class="table">
              <tr class="head_line">
                <td>TJM</td>
                <td colspan="4">{{ consultant.TJM_consultant }} €</td>
              </tr>
              <tr class="head_line">
                <td>Mois</td>
                <td>Jour Travaillés</td>
                <td>Facture HT</td>
              </tr>
              <tr v-for="(dates, month) in groupedDates" :key="month">
                <td>{{ month }}</td>
                <td>{{ dates.length }}</td>
                <td>{{ consultant.TJM_consultant * dates.length }}</td>
              </tr>
              <tr class="head_line">
                <td>Total</td>
                <td colspan="4">{{ montantTotal }} €</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="sidebar box">
        <h2>Calandrier</h2>
        <!-- <div class="calendar-container"> -->
        <!-- <button class="date-picker-btn" @click="openFlatpickr">Ouvrir Calendrier</button> -->
        <div ref="flatpickr" :config="config"></div>

        <!-- </div> -->
        <div class="selected-dates">
          <button class="date-picker-btn" @click="EnvoyerJourDeTravaill">Envoyer</button>
          <!-- <div class="dates-from-calendar">
            <h3>Selectioner Des Dates:</h3>
            <ul>
              <li v-for="date in datesFromCalendar" :key="date" class="date">{{ date }}</li>
            </ul>
            <button class="date-picker-btn" @click="EnvoyerJourDeTravaill">Envoyer</button>
          </div> 
          <h3>Dates sélectionnées:</h3>
          <ul class="dates_selected">
            <li v-for="date in uniqueDates" :key="date">{{ formatMissionDate(date) }}</li>
          </ul>
          -->
        </div>
      </div>
    </div>
  </div>
  <footer></footer>
</template>
  
<script>
import axios from 'axios';
import VueFlatpickr from 'vue-flatpickr-component';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.css';
import { mapGetters } from 'vuex';
import Swal from 'sweetalert2';
import moment from 'moment';

export default {

  name: 'MissionDetails',
  data() {
    return {
      previousDates: [],
      mission: {},
      consultant: {},
      rawDates: [],
      selectedDates: [],
      datesFromCalendar: [],
      flatpickrInstance: null,
      config: {
        inline: true,
        mode: "multiple",
        enableTime: false,
        defaultDate: [],
        onChange: (selectedDates) => {
          const currentSelectedDates = selectedDates.map(date => {
            return moment(date).format('YYYY-MM-DD');
          });
          this.datesFromCalendar = currentSelectedDates.filter(date => !this.previousDates.includes(date));
        }
        // onChange: (selectedDates) => {
        //     this.datesFromCalendar = selectedDates.map(date => date.toISOString().split('T')[0]);
        // }
        // mode: "multiple",
        // enableTime: true,
        // onChange: (selectedDates) => {
        //   this.datesFromCalendar = selectedDates.map(date => date.toISOString().split('T')[0]);
        // }
      }
    };
  },
  computed: {
    ...mapGetters([
      'getconsultant',
    ]),
    uniqueDates() {
      const dates = [...new Set(this.consultant.jour_de_travaill)];
      return dates.map(date => moment(date).format('YYYY-MM-DD'));
    },
    filteredJourDeTravaill() {
      if (this.consultant.jour_de_travaill)
        return this.consultant.jour_de_travaill.filter(date => !this.refusedDates.includes(date));
    },
    montantTotal() {
      if (this.consultant && Array.isArray(this.consultant.jour_de_travaill)) {
        return this.consultant.jour_de_travaill.length * this.consultant.TJM_consultant;
      }
      return 0;
    },
    montantTotalTTC() {
      if (this.consultant && Array.isArray(this.consultant.jour_de_travaill)) {
        const total = (this.consultant.jour_de_travaill.length * this.mission.tarif_mission) * 0.2;
        return total + (this.consultant.jour_de_travaill.length * this.mission.tarif_mission);
      }
      return 0;
    },
    groupedDates() {
      const grouped = {};

      if (this.consultant.jour_de_travaill) {
        this.consultant.jour_de_travaill.forEach(date => {
          const monthYear = moment(date).format('MMMM-YYYY');
          if (!grouped[monthYear]) {
            grouped[monthYear] = [];
          }
          grouped[monthYear].push(date);
        });
      }

      return grouped;
    },
  },
  methods: {
    // async EnvoyerJourDeTravaill() {
    //     try {
    //         if (this.selectedDates) {
    //             let datesArray = this.selectedDates.split(', ')
    //                 .map(dateString => moment(dateString.trim()).format('YYYY-MM-DD'));

    //             const consultantId = this.getconsultant._id;

    //             const response = await axios.put(`http://missions-freelance-industrie.fr/consultants/${consultantId}`, {
    //                 jour_de_travaill: datesArray
    //             });

    //             if (response.data && response.data.message) {
    //                 console.log('Successfully updated jour_de_travaill:', response.data);

    //                 const emailResponse = await this.sendWorkdaysEmail(consultantId, datesArray);
    //                 if(emailResponse) {
    //                     console.log('Successfully sent workdays email.');
    //                 } else {
    //                     console.error('Failed to send workdays email.');
    //                 }
    //             } else {
    //                 console.error('Failed to update jour_de_travaill:', response.data);
    //             }
    //         } else {
    //             console.error('selectedDates is empty or not in the correct format:', this.selectedDates);
    //         }
    //     } catch (error) {
    //         console.error('Error updating jour_de_travaill:', error);
    //     }
    // },

    // async sendWorkdaysEmail(consultantId) {
    //     try {
    //         if (this.selectedDates) {
    //             let formattedDates = this.selectedDates.split(', ').map(dateString => moment(dateString.trim(), 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD'));

    //             const response = await axios.post(`http://missions-freelance-industrie.fr/consultants/${consultantId}/sendWorkdays`, {
    //                 jour_de_travaill: formattedDates
    //             });

    //             if(response.data && response.data.message) {
    //                 return true;
    //             } else {
    //                 return false;
    //             }
    //         } else {
    //             console.error('selectedDates is empty.');
    //             return false;
    //         }
    //     } catch (error) {
    //         console.error('Error sending workdays email:', error);
    //         return false;
    //     }
    // },



    formatMissionDate(date) {
      return moment(date).format('DD-MM-YYYY');
    },
    async EnvoyerJourDeTravaill() {
      try {
        // const datesArray = this.selectedDates.split(', ');
        const datesArray = this.datesFromCalendar;
        const consultantId = this.getconsultant._id;
        // const combinedDates = datesArray;
        const combinedDates = [...new Set([...this.previousDates, ...this.datesFromCalendar])];

        // const currentResponse = await axios.get(`http://missions-freelance-industrie.fr/consultants/${consultantId}`);
        // const currentDates = currentResponse.data.data.jour_de_travaill || [];
        // const combinedDates = [...new Set([...currentDates, ...datesArray])];

        const updateResponse = await axios.put(`http://missions-freelance-industrie.fr/consultants/${consultantId}`, {
          jour_de_travaill: combinedDates
        });

        if (updateResponse.data && updateResponse.data.message) {

          const emailResponse = await this.sendWorkdaysEmail(consultantId, combinedDates);
          if (emailResponse) {
            Swal.fire({
              title: 'Succès',
              text: 'La date a été mise à jour et l\'email a été envoyé.',
              icon: 'success',
              confirmButtonText: 'OK'
            }).then(() => {
              location.reload();
            });
          } else {
            Swal.fire({
              title: 'Erreur',
              text: 'Échec de l\'envoi de l\'email.',
              icon: 'error',
              confirmButtonText: 'OK'
            });
          }
        } else {
          Swal.fire({
            title: 'Erreur',
            text: 'Échec de la mise à jour des dates.',
            icon: 'error',
            confirmButtonText: 'OK'
          });
        }

      } catch (error) {
        Swal.fire({
          title: 'Erreur',
          text: 'Une erreur s\'est produite lors de la mise à jour des dates.',
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }
    },

    async sendWorkdaysEmail(consultantId, datesArray) {
      try {
        const response = await axios.post(`http://missions-freelance-industrie.fr/consultants/${consultantId}/sendWorkdays`, {
          jour_de_travaill: datesArray
        });

        if (response.data && response.data.message) {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        console.error('Error sending workdays email:', error);
        return false;
      }
    },

    openFlatpickr() {
      this.datesFromCalendar = [];
      if (this.flatpickrInstance) {
        this.flatpickrInstance.open();
      }
    },
    async fetchMissionDetail() {
      try {
        const missionId = this.$route.params.id;
        let response = await axios.get(`http://missions-freelance-industrie.fr/missions/consultants/${missionId}`);

        if (response.data) {
          this.mission = response.data.data;
        }

        const consultantId = this.getconsultant._id;
        response = await axios.get(`http://missions-freelance-industrie.fr/consultants/${consultantId}`);

        if (response.data) {
          this.consultant = response.data.data;
          this.previousDates = [...this.consultant.jour_de_travaill];
        }

        // if (response.data) {
        //     this.consultant = response.data.data;
        // }

        // this.config.defaultDate = this.consultant.jour_de_travaill || [];
        this.flatpickrInstance = flatpickr(this.$refs.flatpickr, this.config);
        this.flatpickrInstance.open();
      } catch (error) {
        console.error('Error fetching mission details:', error);
      }
    },

  },
  mounted() {
    this.fetchMissionDetail().then(() => {
      // Format the fetched dates
      const fetchedDates = this.consultant.jour_de_travaill.map(date => moment(date).format('YYYY-MM-DD'));

      // Update the Flatpickr config
      const configWithDates = {
        ...this.config,
        defaultDate: fetchedDates
      };
      this.flatpickrInstance = flatpickr(this.$refs.flatpickr, configWithDates);
      this.flatpickrInstance.open();
    });
    // this.flatpickrInstance = flatpickr(this.$refs.flatpickr, this.config);
    // this.flatpickrInstance.open();
  },
  beforeDestroy() {
    if (this.flatpickrInstance) {
      this.flatpickrInstance.destroy();
    }
  }
};
</script>
  

<style>
.flatpickr-input {
  display: none !important;
}
</style>
<style scoped>
* {
  font-family: Helvetica, sans-serif;
}

.container {
  margin-top: 30px !important;
  margin: 0 auto;
  width: 100%;
}

.in__container {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  -moz-column-gap: 2em;
  column-gap: 2em;
  margin-top: 25px;
}

.container .title {
  width: 90%;
  margin: 0 auto;
}

.in__container .form {
  width: 70%;
}

.page_title,
.card_title {
  font-weight: bold;
}

.page_title {
  text-align: center;
  margin-bottom: 15px;
}

.sub_page_title {
  color: #595959;
  font-weight: 600;
}

.sidebar h2 {
  margin-bottom: 30px;
}

.sidebar .dates-from-calendar,
.dates_selected {
  margin-top: 20px;
}

.in__container .sidebar {
  width: 40%;
  margin-top: 15px;
  padding: 30px 15px;
  border: 1px solid #e9e0e0;
  border-radius: 10px;
}

.cardavalider {
  padding: 30px 20px;
  border: 1px solid #e9e0e0;
  border-radius: 10px;
  margin: 15px 0;
}

.card_body {
  display: flex;
  align-items: center;
}

.desc h3 {
  font-weight: bold;
  margin: 15px 0;
}

.desc .competences,
.description_mission {
  margin: 30px 0;
}

.card_summary {
  column-gap: 1em;
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
}

.card_summary a {
  margin-bottom: 10px;
  color: #333;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.5em;
}

.card_summary i {
  font-size: 20px;
  color: #093c89;
}

.card_content {
  width: 100%;
}

.form-body h1 {
  text-align: center;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.cv_card {
  display: flex;
  align-items: center;
  width: 80%;
  height: 70px;
  padding: 10px 5px;
  margin: 20px 10px;
  border: 1px solid #e9e0e0;
  border-radius: 10px;
  box-shadow: 0px 3px 5px -2px rgba(0, 0, 0, 0.5);
}

.box {
  box-shadow: 0px 3px 5px -2px rgba(0, 0, 0, 0.5);
  background: #fff;
}

.dates-from-calendar {
  min-height: 150px;
}

.date-picker-btn {
  align-items: center;
  margin-top: 15px !important;
}

.dates_selected {
  list-style-type: none;
  display: flex;
  max-width: 100%;
  gap: 10px;
  background: #e5f3ff;
  padding: 10px 5px !important;
  border: 1px solid #d4ebfe !important;
  border-radius: 5px;
  font-weight: 700;
  justify-content: space-evenly !important;
}
.table {
    margin: 20px 0;
    border-collapse: collapse;
    width: 100%;
}

.table .head_line {
    font-weight: bold;
}

.table tr td {
    border: 1px solid #000;
    text-align: center;
    padding: 4px 2px;
}
@media only screen and (max-width: 900px) {

  .in__container {
    flex-direction: column;
    align-items: center;
  }

  .title {
    margin-top: 15px;
  }

  .in__container .form {
    width: 100%;
  }

  .in__container .sidebar {
    width: 100%;
  }

  .card_body {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .card_body .card_summary {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 5px 0;
  }

  .card_summary a {
    margin: 5px 0;
    flex-direction: row;
    align-items: center;
    row-gap: 0.5em;
    column-gap: 0.5em;
  }
}

/* .flatpickr-calendar {
  border-radius: 5px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange {
  background: #ffffff;
  color: #fff;
}

.flatpickr-day:hover {
  background: #19A7CE;
} */

.selected-dates {
  margin-top: 15px;
}

.selected-dates h3 {
  font-size: 16px;
  margin: 20px 0;
}

.selected-dates ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  max-width: 99%;
  gap: 10px;
  justify-content: flex-start;
}

.selected-dates .date {
  background: #e5f3ff;
  border: 1px solid #d4ebfe !important;
  padding: 10px 20px;
  border-radius: 7px;
}

.selected-dates li {
  /* background-color: #f4f4f4; */
  padding: 5px;
  border-radius: 4px;
  white-space: nowrap;
}

.calendar-container {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}



.date-picker-btn {
  background-color: #146C94;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  box-shadow: 0px 3px 5px -2px rgba(0, 0, 0, 0.3);
  width: 150px;
}

.date-picker-btn:hover {
  background-color: #19A7CE;
  box-shadow: 0px 5px 8px -2px rgba(0, 0, 0, 0.4);
}

.sidebar.box {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


div[ref="flatpickr"] {
  margin-bottom: 40px;
}

.selected-dates h3 {
  font-size: 16px;
  margin-top: 40px;
  margin-bottom: 20px;
}
</style>

  