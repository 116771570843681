<template>
    <div class="container">
        <div class="form">
            <h1>Tableau de bord</h1>
            <div class="form-body">

                <div class="row">
                    <router-link to="/listeconsultant"><i class="fa-solid fa-clipboard-user"></i> Liste des
                        consultants</router-link>
                    <router-link to="/MissionsValider"><i class="fa-solid fa-file-circle-exclamation"></i> Liste des
                        missions à valider</router-link>
                </div>
                <div class="row">
                    <router-link to="/MissionsValidees"><i class="fa-solid fa-file-circle-check"></i> Liste des missions
                        validées</router-link>
                    <router-link to="/HistoriqueMissions"><i class="fa-solid fa-file-lines"></i> Historique des
                        missions</router-link>
                </div>
                <div class="row">
                    <router-link to="/MissionsAttribuees"><i class="fa-solid fa-file-arrow-up"></i> Liste des missions
                        attribuées</router-link>
                    <router-link to="/AjouterMission"><i class="fa-solid fa-file-circle-plus"></i> Ajouter une
                        Mission</router-link>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'Dashboard',
    methods: {
        goToPage(direction) {

            this.$router.push(direction)
        }
    }
};
</script>
  
<style scoped>
* {
    font-family: Helvetica, sans-serif;
}

.container {
    width: 90%;
    margin-top: 5% !important;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
    justify-content: space-around;
}

h1 {
    text-align: center;
}

.form-body {
    display: flex;
    flex-direction: row;
}

.row {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.row a {
    text-align: center;
    font-size: 16px;
    color: #fff;
    background: #146C94;
    margin: 20px;
    display: flex;
    width: 200px;
    height: 180px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 1em;
    padding: 10px;
}

.row a:hover {
    background: #19A7CE;
    transition: 0.3s;
}

.row a i {
    padding: 5px;
    font-size: 35px;
}


/******/

@media only screen and (max-width: 540px) {

    .form-body {
        flex-direction: column;
    }

    .row {
        margin: 0;
        /* flex-direction: column; */
        flex-direction: initial;
    }

    .row a {
    text-align: center;
    font-size: 13px;
    color: #fff;
    background: #146C94;
    margin: 20px;
    display: flex;
    width: 11em;
    height: 11em;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 1em;
    padding: 10px;
}
.row a i{
    font-size: 30px;
}

}</style>
  