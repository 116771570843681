<template>
    <SidebarHeader />
    <div class="title">
        <h1 class="page_title">Mes missions</h1>
    </div>
    <div class="container">
        <div class="in__container">
            <div class="form">
                <br>
                <h2 class="sub_page_title">Mes missions récents en attends</h2>
                <Card />
                <Card />
                <input class="button_plus" type="button" value="Plus">
                <br>
                <h2 class="sub_page_title">Mes missions récents en cours</h2>
                <Card />
                <Card />
                <input class="button_plus" type="button" value="Plus">
                <br>
                <h2 class="sub_page_title">Mes missions terminés</h2>
                <Card />
                <Card />
                <input class="button_plus" type="button" value="Plus">
            </div>
            <div class="sidebar box">
            </div>
        </div>
    </div>
</template>
  
<script>

import SidebarHeader from '../../components/SidebarHeader.vue';
import Card from '../../components/CardComp.vue';

export default {
    components: {
        SidebarHeader,
        Card,

    },
}
</script>
  
<style scoped>
* {
    font-family: Helvetica, sans-serif;
}

.title {
    margin: 0 auto;
    width: 90%;
    margin-top: 100px;
    text-align: center;
}

.page_title,
.card_title {
    font-weight: bold;
}

.sub_page_title {
    color: #595959;
    font-weight: 600;
}

.in__container {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

.container .title {
    width: 90%;
    margin: 0 auto;
}

.in__container .form {
    width: 57%;
    display: flex;
    flex-direction: column;
}

.page_title,
.card_title {
    font-weight: bold;
}

.in__container .sidebar {
    width: 40%;
    margin: 15px;
    padding: 15px 0px;
    /*border: 1px solid #e9e0e0;
    border-radius: 10px;
    display: flex;
    justify-content: center;*/
}

.button_plus {
    padding: 10px 50px;
    font-size: 17px;
    font-weight: bold;
    background: #D9D9D9;
    color: #595959;
    border: none;
}

.button_plus:hover {
    background-color: #595959;
    color: #fff;
    transition: 0.3s;

}

@media only screen and (max-width: 750px) {

    .container {
        margin-top: 10% !important;
        flex-direction: column-reverse;
        display: flex;
        align-items: center;
    }

    .in__container {
        flex-direction: column;
        align-items: center;
    }

    .in__container .form {
        width: 100%;
    }


    .container .sidebar {
        width: 100%;
        padding: 30px 0px;
        text-align: center;
    }

    .row {
        margin: 0;
        flex-direction: column;
    }

    .card_body {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .card_actions {
        width: 50%;
        display: flex;
        margin-top: 20px;
        flex-direction: row;
        justify-content: space-around;
    }

    .card_actions svg {
        font-size: 40px;
    }

    .card_body .card_summary {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding: 5px 0;
    }

    .card_summary a {
        margin: 5px 0;
    }
}</style>