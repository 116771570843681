<template>
  <!--<a href="#cont" style="position: fixed;right: 0;top: 10%;z-index: 99999;text-decoration: none;background: #146C94;padding: 5px;color: #fff;border-radius: 5px;">Annonces</a>-->
  <div class="s">
    <Navbar />
    <div class="header_annonces">
      <div class="slider">
          <div class="slide">
            <p>Rejoignez notre équipe</p>
          </div>
          <div class="slide">
            <p>Consulting</p>
          </div>
          <div class="slide">
            <p>Conseil Automobile</p>
          </div>
          <div class="slide">
            <p>Ferroviaire</p>
          </div>
        </div>
      <div class="header-text">
        <!--
        <div>
          <p>Nous vous aidons à trouver rapidement <br> un consultant freelance disponible pour venir renforcer vos
            équipes
          </p>
        </div>
        <img :src="background" alt="my-logo" />
      -->
        
      </div>

    </div>
    
  </div>
  <div class="cont" ref="missionsList">
    <Search @filter="handleFilter" />

    <!--<Options /> -->
    <div class="container missioncontainer">
      <Carte :searchType="searchType" :searchQuery="searchQuery" />
    </div>

  </div>

  <Footer />
</template>

<script>

import Navbar from '../../components/Pages-annonces/Navbar.vue';
import Options from '../../components/Pages-annonces/Options.vue';
import Search from '../../components/Pages-annonces/Search.vue';
import Footer from '../../components/Pages-annonces/Footer.vue';
import Carte from '../../components/Pages-annonces/Carte.vue';
import background from "@/assets/background.svg";

export default {


  name: "Annonces",

  setup() {
    return {
      background
    };
  },
  components: {
    Navbar,
    //Options,
    Search,
    Footer,
    Carte
  },
  data() {
    return {
      searchType: 'nom_mission', // Default search type
      searchQuery: '',
    };
  },
  methods: {
    handleFilter(filterCriteria) {
      // Receive search criteria from Search component
      this.searchType = filterCriteria.searchType;
      this.searchQuery = filterCriteria.searchQuery;
    },
    // Other methods and logic here
  },

}
</script>

<style src="../Pages-annonces/Annonces.vue">
.s {
  background-image:
    linear-gradient(rgb(255 255 255 / 0%), rgb(246 241 241));
  /* url(http://localhost:8081/img/bg_annonces.png)*/
  /* url(../../assets/background.svg); */
  background-size: cover;
}

.s .header-text img {
  width: 50%;
}

/* .header-text p {
  width: 45%; 
} */


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

}

.container {
  margin: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.missioncontainer>div {
  width: 100%;
  align-items: -webkit-center;
}

body {
  margin: 0 !important;
  font-size: 14px;
  background-color: #F6F1F1;
  width: 100%;
}

/* .header_annonces {
  height: 500px;
  text-align: center;
  padding: 60px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 51%, rgba(246, 241, 241, 1) 100%);
} */

.header-text {
  color: #000;
  text-shadow: 2px 5px 6px #b8b8b8;
  font-size: 30px;
  font-weight: bold;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-top: 6%; */
  margin-top: 1em;
}

/* @media only screen and (min-width: 992px) {
  .cont {
    width: 75%;
  }

  .search {
    width: 85%
  }

  .card {
    width: 75%;
  }
} */

.cont {
  width: 80%;
  margin: auto;
  min-height: 300px;
}

/* @media only screen and (min-width: 300px) {
  .cont {
    width: 100%;
  }

  .search {
    width: 85%
  }

  .card {
    width: 85%;
  }
} */
@media (max-width: 768px) {
  .s .header-text img {
    width: 100%;
  }

  .header-text p {
    font-size: 0.6em;
  }

  .header-text {
    align-items: center;
    justify-content: space-between;
    margin-top: 6%;
    flex-direction: column-reverse;
    row-gap: 1em;
  }

}

.slider {
    display: block;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    /* position: absolute; */
    position: relative;
  }
  .slide {
  position: relative;
}

.slide::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Adjust the opacity for darkness */
  z-index: 1; /* Ensure the dark layer is on top of the image */
}

  .slider>* {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    animation: slide 12s infinite;
  }

  .slide:nth-child(1) {
    left: 0%;
    animation-delay: -1s;
    background-image: url(../../assets/1.jpg);
    background-size: cover;
    background-position: center;
  }

  .slide:nth-child(2) {
    left: 100%;
    animation-delay: 2s;
    background-image: url(../../assets/2.jpg);
    background-size: cover;
    background-position: center;
  }

  .slide:nth-child(3) {
    left: 100%;
    animation-delay: 5s;
    background-image: url(../../assets/3.jpg);
    background-size: cover;
    background-position: center;
  }

  .slide:nth-child(4) {
    left: 100%;
    animation-delay: 8s;
    background-image: url(../../assets/4.jpg);
    background-size: cover;
    background-position: center;
  }

  .slide p {
    font-family: sans-serif;
    font-size: 70px;
    text-align: center;
    display: block;
    width: 100%;
    color: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    z-index: 2;
  }

  @keyframes slide {
    0% {
      left: 100%;
      width: 100%;
      opacity: 1;
    }

    5% {
      left: 0%;
    }

    25% {
      left: 0%;
    }

    30% {
      left: -100%;
      width: 100%;
      opacity: 1;
    }

    30.0001% {
      left: -100%;
      width: 0%;
      opacity: 0;
    }

    100% {
      left: 100%;
      width: 100%;
      opacity: 0;
    }
  }
</style>
