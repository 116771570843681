<template>
  <div class="search">
    <div class="search-input">
    
    <input type="text" v-model="searchQuery" @input="filterData" placeholder="Recherche">
    <select v-model="searchType">
      <option value="all">Toutes les Missions</option>
      <option value="nom_mission">Nom Mission</option>
      <option value="description_mission">Description Mission</option>
      <option value="competences_requises">Compétences Requises</option>
      <option value="ville">Ville</option>
    </select>
  </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchType: 'all', // Default search type
      searchQuery: '',
    };
  },
  methods: {
    filterData() {
      // Emit an event to notify the parent component
      this.$emit('filter', { searchType: this.searchType, searchQuery: this.searchQuery });
    },
  },
}
</script>

<style>
.search {
  width: 70%;
    margin: 0 auto;
    box-shadow: 0px 2px 7px 0px #dfdcdc;
}
.search select {
    line-height: 2em;
    padding: 5px;
    font-size: 14px;
    border: none;
    border-left: 1px solid #ccc;
    border-radius: 0px 4px 4px 0px;
    color: #828482;
    outline: none;
}
.search select:focus {
  border: 1px solid #221d1d;
}
.search option {
  line-height: 3em;
    padding: 5px;
    font-size: 14px;
    color: #828482;
}

.search-input {
  display: flex;
  margin-top: 20px;
  text-align: center;
}

.search-input input[type="text"] {
  width: 100%;
  line-height: 2em;
  padding: 5px;
  font-size: 14px;
  border: none;
  outline: none;
  border-radius: 4px 0px 0px 4px;
  }
  .search-input input[type="text"]:focus {
    border: 1px solid #221d1d;
  }
</style>
