
<template>
  <header>
    <nav>
      <router-link to="/" id="logo">Logo</router-link>
      <ul id="menu">
        <ul class="center_menu">
          <li @click="navigateToAnnonces"><router-link to="/">Annonces</router-link></li>
        </ul>
        <li class="login-button"><router-link :to="getAlternateLink">{{ getLinkText() }}</router-link></li>
      </ul>


      <div id="hamburger">
        <div class="line" id="one"></div>
        <div class="line" id="two"></div>
        <div class="line" id="three"></div>
      </div>
    </nav>

    <div class="mobile-menu">
      <ul class="mobile-menu__items">
        <li><router-link to="/">Annonces</router-link></li>
        <li><router-link :to="getAlternateLink">{{ getLinkText() }}</router-link></li>
      </ul>
    </div>
  </header>
  <!--
  <div>
    
    <div class="nav-menu">

      <a class="logo_s">logo</a>
      <i class="fas fa-bars" @click="showMenu()">
      </i>
      <div class="nav-content" :class="this.showMobileMenu ? 'open-menu' : 'closed-menu'">
        <div class="logo-wrapper">
          <router-link to="/" class="logo">Logo</router-link>
        </div>
        <ul class="nav-items">
          <li><router-link to="/">Annonces</router-link></li>
        </ul>
        <ul>
          <li class="login-button">
            <router-link :to="getAlternateLink">{{ getLinkText() }}</router-link>
          </li>
        </ul>
      </div>
    </div>
    
  </div>
  -->
</template>
<script>
export default {
  data() {
    return {
      showMobileMenu: false,
    };
  },
  computed: {
    isAdminLoggedIn() {
      const adminToken = localStorage.getItem('admin_token');
      return !!adminToken;
    },
    isConsultantLoggedIn() {
      const consultantToken = localStorage.getItem('consultant_token');
      return !!consultantToken;
    },
    getAlternateLink() {
      if (this.isAdminLoggedIn) {
        return '/dashboard';
      } else if (this.isConsultantLoggedIn) {
        return '/missionconsultant';
      } else {
        return '/loginconsultant';
      }
    },
  },
  methods: {
    showMenu() {
      this.showMobileMenu = !this.showMobileMenu;
    },
    getLinkText() {
      if (this.isAdminLoggedIn) {
        return 'Dashboard';
      } else if (this.isConsultantLoggedIn) {
        return 'Mon Espace';
      } else {
        return 'Connexion';
      }
    },
    async navigateToAnnonces() {
      await this.$router.push({ name: 'annonces' })
      // .then(()=>{
        // setTimeout(() => {
          const missionsListRef = this.$parent.$refs.missionsList;
          if (missionsListRef) {

        missionsListRef.scrollIntoView({ behavior: 'smooth' });
      }
        // }, 3000);
      // });

      
      

    },
  },
  mounted() {
    const hamburgerButton = document.getElementById("hamburger");
    const mobileMenu = document.querySelector(".mobile-menu");

    hamburgerButton.addEventListener("click", function () {
      this.classList.toggle("active");
      mobileMenu.classList.toggle("active");
    });
  },

};
</script>
<style scoped>
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
  list-style: none;
  text-decoration: none;
}

header {
  width: 100%;
  margin: 0 auto;
}

.center_menu {
  width: 74%;
  text-align: center;
  display: flex;
  /* justify-content: center; */
  justify-content: end;
}

nav {
  width: 80%;
  background: none;
  height: 70px;
  z-index: 1;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  padding: 0 10px;
  font-size: 16px;
}

nav #logo {
  font-size: 40px;
  font-weight: bold;
  color: #000;
}

nav #menu {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  /* justify-content: space-between; */
  column-gap: 5em;
    justify-content: flex-end;
}

nav #menu li a {
  padding: 25px;
  cursor: pointer;
  line-height: 70px;
  transition: all 0.3s ease-out;
}

nav #menu li a:hover {
  background: #146C94;
  color: #fff;
}

nav #menu li a {
  color: #000;
}

.login-button a {
  cursor: pointer;
  line-height: 70px;
  transition: all 0.3s ease-out;
  color: #fff !important;
  background-color: #146C94 !important;
}

.login-button a {
  color: #fff !important;
}

.login-button a:hover {
  background-color: #218ec0 !important;
}

@media (max-width: 768px) {
  nav #menu {
    display: none;
  }
}

#hamburger {
  position: absolute;
  right: 10px;
  top: 14px;
  z-index: 999;
  width: 40px;
  height: 40px;
  cursor: pointer;
  transition: all 0.3s ease-out;
  visibility: hidden;
  opacity: 0;
}

#hamburger .line {
  height: 7px;
  background: #444;
  margin: 5px auto;
  backface-visibility: hidden;
}

#hamburger.active #one {
  transform: rotate(45deg) translateX(6px) translateY(6px);
}

#hamburger.active #two {
  opacity: 0;
}

#hamburger.active #three {
  transform: rotate(-45deg) translateX(10px) translateY(-12px);
}

@media (max-width: 768px) {
  #hamburger {
    visibility: visible;
    opacity: 1;
    z-index: 9999999999;
  }

  .mobile-menu {
    width: 100% !important;
  }
}

.mobile-menu {
  z-index: 1;
  position: absolute;
  top: 0px;
  background: #ddd;
  width: auto;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-out;
  display: table;
}

.mobile-menu .mobile-menu__items {
  height: 50px;
  display: table-cell;
  vertical-align: middle;
}

.mobile-menu .mobile-menu__items li a {
  display: block;
  text-align: center;
  padding: 20px 0;
  text-align: center;
  font-size: 35px;
  min-height: 50px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.mobile-menu .mobile-menu__items li a:hover {
  color: #fff;
  background: #146C94;
}

.mobile-menu .mobile-menu__items li a:hover {
  color: #fff;
}

.mobile-menu .mobile-menu__items li a {
  color: #212121;
}

.mobile-menu.active {
  visibility: visible;
  opacity: 0.99;
  z-index: 9999999;
}

@media (min-width: 768px) {
  header {
    /* width: 80% !important; */
    position: absolute;
    z-index: 125;
    background: rgb(255 255 255 / 72%);
    display: flex;
    justify-content: center;
  }

  .mobile-menu {
    visibility: hidden !important;
  }
}
</style>
<!-- <style scoped>
.logo_s {
  display: none;
}

.nav-menu {
  background: none;
  width: 80%;
  margin: 0 auto;
  line-height: 3em;
}

.nav-content {
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
  align-items: center;
}

.nav-items {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  column-gap: 4em;

  li {
    padding: 0 10px;
    color: #000;
  }
}

a {
  color: #000;
}

i {
  display: none;
}

.logo {
  padding: 5px 30px;
}

.login-button {
  padding: 5px 30px;
  color: #fff;
  background-color: #146C94;
  list-style: none;
}

.login-button a {
  color: #fff;
}

@media screen and (max-width: 768px) {
  .logo_s {
    display: block;
  }

  .logo-wrapper {
    display: block;
    /* Show the logo on smaller screens */
    text-align: center;
    /* Center the logo */
  }

  .logo {
    margin: 10px 0;
    /* Add margin for spacing */
  }

  .nav-menu {
    background: #146C94;
    padding: 20px 0;
    width: 100%;
    display: inline-grid;
    justify-items: center;
  }

  .open-menu {
    opacity: 1;
  }

  .closed-menu {
    opacity: 0;
    height: 0;
    padding: 0;
  }

  .nav-content {
    flex-direction: column;
    z-index: 100;
    position: relative;
    transition: all 0.2s ease-out;
  }

  .nav-items {
    flex-direction: column;
    color: #fff;
  }

  i {
    display: block;
    text-align: right;
    padding: 0 20px;
    color: #fff;
  }

  .login-button {
    padding: 0;
    background: none;
    color: #fff;
  }
}</style> -->

