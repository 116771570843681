<template>
    
    <Navbar />
    <DetailsConsultant />
  
  </template>
  
  <script>
  
  import Navbar from '../../components/Pages-admine/Navbar.vue';
  import DetailsConsultant from '../../components/MissionsDetailsConsultant.vue';
  
  export default {
    components: {
      Navbar,
      DetailsConsultant,
  
    },}
  </script>
  
  <style>
  
  </style>