<template>
    <Navbar />
    <div class="details">
        <h3>Détails</h3>
        <div class="details-container">
            <h4>Nom</h4>
            <input type="text" name="text" id="text" class="inpt1" placeholder="Entrez Votre Nom">
            <h4>Username</h4>
            <input type="text" name="username" id="username" class="inpt1" placeholder="admin_123">
            <h4>Email</h4>
            <input type="email" name="email" id="email" class="inpt1" placeholder="text@email.com">
            <button class="modifier">Modifier</button>
        </div>
     </div>
     <div class="mdp">
        <div class="mdp-container">
                <h4>Mot de passe</h4>
                <input type="password" name="password" id="password" class="inpt1" placeholder="********">
                <h4 style="">Nouveau Mdp</h4>
                <input type="password" name="password" id="password" class="inpt1" placeholder="********">
        </div>
     </div>
    <div class="titleandframe">
    <h1 >Profile Client</h1>
        <div class="title-frame">
            <img src="../../assets/Ellipse2.png" />
            <h2 style="margin-top: 15px;">Admin</h2>
        </div>
    </div>
</template>

<script>
import Navbar from '../../components/Pages-admine/Navbar.vue'

export default {
    components: {
    Navbar,
  },
}
</script>

<style scoped>
h1 {
  color: #000;
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
  margin-top: 70px;
  margin-right: 700px;
}

.title-frame {
  display: block; /* Change display from inline-flex to block */
  padding: 40px;
  margin: 0 auto; /* To center the title-frame horizontally */
  width: 300px; /* Set a width to the title-frame if needed */
  box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.05);
  border-radius: 18px;
  background: #F6F1F1;
  width: 280px;
  height: 325px;
  margin-top: 30px;
  margin-left: 200px;
}

.title-frame h3 {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
}

.details {
  position: relative; /* Use relative positioning instead of absolute */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 200px; /* Adjust the vertical position of .details */
  margin-left: 150px; /* Adjust the horizontal position of .details */
}
.details h3{
    margin-right: 300px;
}
.details-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px; /* Adjust the vertical spacing between .details-container */
}

.details-container h4 {
  /* Your existing styles for .details h4 */
  margin: 30px 20px 0 0; /* Adjust margins for .details h4 (top, right, bottom, left) */
}

.details-container .inpt1 {
  /* Your existing styles for .details input */
  width: 352px;
  height: 30px;
  border-radius: 0px;
  border: none; /* Remove any existing border */
  border-bottom: 1px solid black;
  background: #F6F1F1;
  margin-top: 5px; /* Adjust the top margin for spacing */
}
.titleandframe .title-frame{
    position: absolute;
    top: 150px;
    left: -50px;
}
.titleandframe h1{
    position: absolute;
    top: 50px;
    left: 160px;
}
.modifier {
  background-color: rgba(20, 108, 148, 0.95);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  width: 280px;
  cursor: pointer;
  margin-top: 40px;
  margin-left: 40px;
}

.modifier:hover {
  background-color: darkblue; 
}
.mdp {
  position: relative; /* Use relative positioning instead of absolute */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 200px; /* Adjust the vertical position of .details */
  margin-left: 150px; /* Adjust the horizontal position of .details */
  float:right;
}
.mdp h4{
    margin-right: 220px;
}
.mdp .inpt1{
    width: 352px;
    height: 30px;
    border-radius: 0px;
    border: none; /* Remove any existing border */
    border-bottom: 1px solid black;
    background: #F6F1F1;
    margin-top: 5px; /* Adjust the top margin for spacing */
    margin-left: 40px;
}
.mdp-container h4{
   margin: 30px 220px 2px 0;
}
.mdp-container{
    position: absolute;
    bottom: 355px;
    right: 20px;
}
</style>