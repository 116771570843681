<template>
    <Navbar />
    <DetailsAnnonce />
    <Footer />
</template>

<script>
import Navbar from '../../components/Pages-annonces/Navbar.vue'
import DetailsAnnonce from '../../components/DetailsAnnonce.vue';
import Footer from '../../components/Pages-annonces/Footer.vue';


export default {
    components: {
    Navbar,
    DetailsAnnonce,
    Footer,
  },
}
</script>
<style scoped>
@media (min-width: 768px){
header{
    /* width: 80% !important; */
    position: relative;
    z-index: 125;
    background: rgb(255 255 255 / 72%);
    display: flex;
    justify-content: center;
}
}
</style>