<template>
    <div class="container">
        <div class="rectangle-login">
            <h2>Reset Your Password</h2>
            <form @submit.prevent="resetPassword" class="inputs">
                <div>
                    <label for="newPassword">New Password</label>
                    <input type="password" id="newPassword" v-model="newPassword" class="inpt1" required />
                </div>
                <div>
                    <label for="confirmPassword">Confirm Password</label>
                    <input type="password" id="confirmPassword" v-model="confirmPassword" class="inpt1" required />
                </div>
                <div>
                    <button type="submit" class="cnx">Reset Password</button>
                </div>
                <p>{{ message }}</p>
            </form>
        </div>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            newPassword: '',
            confirmPassword: '',
            message: '',
        };
    },
    methods: {
        async resetPassword() {
            if (this.newPassword !== this.confirmPassword) {
                this.message = 'Passwords do not match.';
                return;
            }

            const resetToken = this.$route.params.resetToken;

            try {
                const response = await fetch(`http://missions-freelance-industrie.fr/consultant/reset-password`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ resetToken, newPassword: this.newPassword }),
                });

                const data = await response.json();
                if (response.ok) {
                    this.message = data.message;
                    setTimeout(()=>{;
                        this.$router.push('/loginconsultant');
                    }, 2000);
                } else {
                    this.message = data.message || 'Password reset failed.';
                }
            } catch (error) {
                console.error(error);
                this.message = 'An error occurred while resetting the password.';
            }
        },
    },
};
</script>
<style scoped>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-size: 14px;
  background-color: #F6F1F1;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rectangle-login {
  width: 400px;
  padding: 20px;
  border-radius: 15px;
  border: 1px solid #DFDFDF;
  background: #FFF;
  box-shadow: 1px 1px 9px 0px rgba(132, 132, 132, 0.25);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.rectangle-login h1 {
  color: #000;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-right: 270px;
  margin-top: 10px;
}

.inputs label {
  color: #595959;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 15px;
}

.inputs .inpt1 {
  width: 100%;
  height: 30px;
  border-radius: 8px;
  border: 1px solid rgba(20, 108, 148, 0.95);
  background: #FFF;
  margin: 15px 0;
  text-indent: 10px;
}

.inpt1::placeholder {
  color: #B8B8B8;
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-indent: 10px;
}

.cnx {
  width: 100%;
  margin: 30px 0 15px 0;
  background-color: rgba(20, 108, 148, 0.95);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;

}

.cnx:hover {
  background-color: darkblue;
}
@media only screen and (max-width: 600px) {
  .rectangle-login {
    width: 350px;
  }
}
</style>
  