import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import Annonces from '../views/Pages-annonces/Annonces.vue'
import Login from '../views/Login-Consultant.vue'
import Loginadmin from '../views/Login-Admin.vue'
import Registeradmin from '../views/Register-admin.vue'
import Registerconsultant from '../views/Register-consultant.vue'
import ListeConsultant from '../views/Pages-consultant/ListeConsultant.vue'
import ProfileAdmine from '../views/Pages-admin/ProfileAdmine.vue'
import ModifierConsultant from '../views/Pages-consultant/ModifierConsultant.vue'
import AjouterConsultant from '../views/Pages-consultant/AjouterConsultant.vue'
import AjouterMission from '../views/Pages-mission/AjouterMission.vue'
import ModifierMission from '../views/Pages-mission/ModifierMission.vue'
import MissionConsultant from '../views/Pages-mission/MissionConsultant.vue'
import Missionencours from '../views/Pages-mission/Missionencours-client.vue'
import ProfileConsultant from '../views/Pages-consultant/ProfileConsultant.vue'
import ProfileClient from '../views/Pages-client/ProfileClient.vue'

import Postuler from '../views/Pages-candidature/PostulerView.vue'
import Dashboard from '../views/DashboardView.vue'
import MissionsValider from '../views/Pages-mission/MissionsValiderView.vue'
import MissionsValidees from '../views/Pages-mission/MissionsValideesView.vue'
import HistoriqueMissions from '../views/Pages-mission/HistoriqueMissionsView.vue'
import DetailMissionaVailder from '../views/Pages-mission/DetailMissionaVailder.vue'
import MissionDetails from '../views/Pages-mission/MissionDetailsView.vue'
import MissionsTermineDetail from '../views/Pages-mission/MissionsTermineDetail.vue'
import DetailsMissionsConsultant from '../views/Pages-mission/DetailsMissionsConsultantView.vue'
import MissionsClient from '../views/Pages-mission/MissionsClientView.vue'
import MissionAttribuer from '../views/Pages-mission/MissionAttribuerView.vue'
import DetailsAnnoncesView from '../views/Pages-annonces/DetailsAnnoncesView.vue'
import Typeform from '../views/Typeform.vue'
import MissionsAttribuees from '../views/Pages-mission/MissionsAttribueesView.vue'
import MissionAttribueeDetails from '../views/Pages-mission/MissionAttribueeDetailsView.vue'
import NotFound from '../views/404.vue';
import RequestPasswordReset from '../views/Pages-consultant/RequestPasswordReset.vue'
import ResetPassword from '../views/Pages-consultant/ResetPassword.vue'
import store from "../store/index.js";


const routes = [
  {
    path: '/',
    name: 'annonces',
    component: Annonces
  },
  {
    path: '/request-password',
    name: 'request-password',
    component: RequestPasswordReset,
  },
  {
    path: '/reset-password/:resetToken',
    name: 'resetPassword',
    component: ResetPassword,
  },
  {
    path: '/typeforminputs',
    name: 'typeforminputs',
    component: Typeform,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('admin_token')) {
        next();
      } else {
        next('/loginadmin');
      }
    }
  },
  {
    path: '/typeforminput/:id',
    name: 'typeforminput',
    component: Typeform,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('admin_token')) {
        next();
      } else {
        next('/loginadmin');
      }
    }
  },
  {
    path: '/loginconsultant',
    name: 'login',
    component: Login
  },
  {
    path: '/loginadmin',
    name: 'loginadmin',
    component: Loginadmin
  },
  {
    path: '/registeradmin',
    name: 'registeradmin',
    component: Registeradmin,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('admin_token')) {
        next();
      } else {
        next('/loginadmin');
      }
    }
  },
  {
    path: '/registerconsultant',
    name: 'registerconsultant',
    component: Registerconsultant,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('admin_token')) {
        next();
      } else {
        next('/loginadmin');
      }
    }
  },
  {
    path: '/registrerconsultant/:id',
    name: 'registrerconsultant',
    component: Registerconsultant,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('admin_token')) {
        next();
      } else {
        next('/loginadmin');
      }
    }
  },
  {
    path: '/listeconsultant',
    name: 'listeconsultant',
    component: ListeConsultant,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('admin_token')) {
        next();
      } else {
        next('/loginadmin');
      }
    }
  },
  {
    path: '/profileadmine',
    name: 'profileadmine',
    component: ProfileAdmine,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('admin_token')) {
        next();
      } else {
        next('/loginadmin');
      }
    }
  },
  {
    path: '/modifierconsultants',
    name: 'modifierconsultants',
    component: ModifierConsultant,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('admin_token')) {
        next();
      } else {
        next('/loginadmin');
      }
    }
  },
  {
    path: '/modifierconsultant/:id',
    name: 'modifierconsultant',
    component: ModifierConsultant,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('admin_token')) {
        next();
      } else {
        next('/loginadmin');
      }
    }
  },
  {
    path: '/ajouterconsultant',
    name: 'ajouterconsultant',
    component:   AjouterConsultant,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('admin_token')) {
        next();
      } else {
        next('/loginadmin');
      }
    }
  },
  {
    path: '/ajoutermission',
    name: 'ajoutermission',
    component: AjouterMission,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('admin_token')) {
        next();
      } else {
        next('/loginadmin');
      }
    }
  },
  {
    path: '/modifiermission',
    name: 'modifiermission',
    component: ModifierMission,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('admin_token')) {
        next();
      } else {
        next('/loginadmin');
      }
    }
  },
  {
    path: '/modifiermission/:id',
    name: 'modifiermission',
    component: ModifierMission,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('admin_token')) {
        next();
      } else {
        next('/loginadmin');
      }
    }
  },
  {
    path: '/missionconsultant',
    name: 'missionconsultant',
    component: MissionConsultant,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('consultant_token')) {
        next();
      } else {
        next('/loginconsultant');
      }
    }
  },
  {
    path: '/missionclient',
    name: 'missionclient',
    component: Missionencours,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('admin_token')) {
        next();
      } else {
        next('/loginadmin');
      }
    }
  },
  {
    path: '/profileconsultant',
    name: 'profileconsultant',
    component: ProfileConsultant,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('consultant_token')) {
        next();
      } else {
        next('/loginconsultant');
      }
    }
  },
  {
    path: '/profileclient',
    name: 'profileclient',
    component: ProfileClient,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('admin_token')) {
        next();
      } else {
        next('/loginadmin');
      }
    }
  },
  {
    path: '/missiondetail',
    name: 'MissionDetail',
    component: MissionDetails,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('admin_token')) {
        next();
      } else {
        next('/loginadmin');
      }
    }
  },
  {
    path: '/missiondetails/:id',
    name: 'MissionDetails',
    component: MissionDetails,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('admin_token')) {
        next();
      } else {
        next('/loginadmin');
      }
    }
  },
  {
    path: '/missionterminerdetails/:id',
    name: 'missionterminerdetails',
    component: MissionsTermineDetail,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('consultant_token')) {
        next();
      } else {
        next('/loginconsultant');
      }
    }
  },
  {
    path: '/missiondetailsavalider/:id',
    name: 'missiondetailsavalider',
    component: DetailMissionaVailder,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('admin_token')) {
        next();
      } else {
        next('/loginadmin');
      }
    }
  },
  {
    path: '/MissionDetails/:id',
    name: 'MissionDetailsbyid',
    component: MissionDetails,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('admin_token')) {
        next();
      } else {
        next('/loginadmin');
      }
    }
  },
  {
    path: '/HistoriqueMissions',
    name: 'HistoriqueMissions',
    component: HistoriqueMissions,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('admin_token')) {
        next();
      } else {
        next('/loginadmin');
      }
    }
  },

  {
    path: '/MissionsValidees',
    name: 'MissionsValidees',
    component: MissionsValidees,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('admin_token')) {
        next();
      } else {
        next('/loginadmin');
      }
    }
  },

  {
    path: '/MissionsValider',
    name: 'MissionsValider',
    component: MissionsValider,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('admin_token')) {
        next();
      } else {
        next('/loginadmin');
      }
    }
  },

  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('admin_token')) {
        next();
      } else {
        next('/loginadmin');
      }
    }
  },

  {
    path: '/Postulers',
    name: 'Postulers',
    component: Postuler,
    // beforeEnter: (to, from, next) => {
    //   if (localStorage.getItem('admin_token')) {
    //     next();
    //   } else {
    //     next('/loginadmin');
    //   }
    // }
  },
  {
    path: '/Postuler/:id',
    name: 'Postuler',
    component: Postuler,
    // beforeEnter: (to, from, next) => {
    //   if (localStorage.getItem('admin_token')) {
    //     next();
    //   } else {
    //     next('/loginadmin');
    //   }
    // }
  },  {
    path: '/MissionsAttribuees',
    name: 'MissionsAttribuees',
    component: MissionsAttribuees
  },
  {
    path: '/MissionAttribueeDetails/:id',
    name: 'MissionAttribueeDetails',
    component: MissionAttribueeDetails
  },

  /* Consulatnt */

  {
    path: '/MissionsDetails',
    name: 'DetailsMissionsConsultant',
    component: DetailsMissionsConsultant,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('consultant_token')) {
        next();
      } else {
        next('/loginconsultant');
      }
    }
  },
  {
    path: '/MissionsDetail/:id',
    name: 'DetailsMissionConsultant',
    component: DetailsMissionsConsultant,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('consultant_token')) {
        next();
      } else {
        next('/loginconsultant');
      }
    }
  },

  /* Consulatnt */

  /* Client */

  {
    path: '/MissionsClient',
    name: 'MissionsClient',
    component: MissionsClient,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('admin_token')) {
        next();
      } else {
        next('/loginadmin');
      }
    }
  },

  {
    path: '/MissionAttribuer',
    name: 'MissionAttribuer',
    component: MissionAttribuer,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('admin_token')) {
        next();
      } else {
        next('/loginadmin');
      }
    }
  },
  {
    path: '/detailsannoncees',
    name: 'detailsannoncees',
    component: DetailsAnnoncesView,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('admin_token')) {
        next();
      } else {
        next('/loginadmin');
      }
    }
  },
  {
    path: '/detailsannonces/:id',
    name: 'detailsannonces',
    component: DetailsAnnoncesView,
    // beforeEnter: (to, from, next) => {
    //   if (localStorage.getItem('admin_token')) {
    //     next();
    //   } else {
    //     next('/loginadmin');
    //   }
    // }
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound
  }

  /* Client */
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

// router.beforeEach((to, from, next) => {
//   const adminToken = store.getters.adminToken || localStorage.getItem('admin_token');
//   const consultantToken = store.getters.consultantToken || localStorage.getItem('consultant_token');

//   if (to.path !== '/loginadmin' && to.path !== '/login' && to.path !== '/registeradmin' && !adminToken && !consultantToken) {
//     if (to.path.startsWith('/consultant')) {
//       next('/login');
//     } else {
//       next('/loginadmin');
//     }
//   } else if ((to.path === '/loginadmin' && adminToken) || (to.path === '/login' && consultantToken)) {
//     if (adminToken) {
//       next('/dashboard');
//     } else {
//       next('/');
//     }
//   } else {
//     next();
//   }
// });


/*
const router = VueRouter.createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: VueRouter.createWebHashHistory(),
  routes, // short for `routes: routes`
})
*/
export default router
