<template>
    <div class="container">
        <div class="title">
            <h1 class="page_title">Details de mission à attribuer</h1>
        </div>
        <div class="in__container">
            <div class="form">
                <div class="form-body">
                    <div class="row">
                        <div class="cardavalider box">
                            <div class="card_body">
                                <div class="card_content">
                                    <h2 class="card_title">Ingénieur Planning / OPC</h2>
                                    <div class="card_description">
                                        <p>Nous recherchons un développeur Sénior Full Stack pour une mission de 3 mois
                                            renouvelables horizon longue durée sur Paris Intramuros. Intégré(e) au sein
                                            d’une équipe</p>
                                    </div>
                                    <div class="card_summary">
                                        <a><i class="fa-regular fa-clock"></i> Publié le : 24/06/2023 </a>
                                        <a><i class="fa-solid fa-location-dot"></i> Marrakech, Maroc</a>
                                        <a><i class="fa-solid fa-hourglass-start"></i> Durée : 6 mois</a>
                                        <a><i class="fa-regular fa-calendar-days"></i> Démarage : 07/07/2023</a>
                                    </div>
                                    <div class="desc">
                                        <div class="competences">
                                            <h3>Compétences requises</h3>
                                            <p>AZURE, MICROSOFT, MICROSOFT, DYNAMICS 365, Anglais</p>
                                        </div>
                                        <div class="description_mission">
                                            <h3>Description de la mission</h3>
                                            <p>
                                                Nous recherchons un Chef de projet infrastructures pour une mission de 3
                                                mois renouvelables

                                                Missions Principales:

                                                - Responsable de la coordination des projets techniques transverses qui lui
                                                sont confiés
                                                - Coordination transverse des équipes d’infrastructure et de support
                                                - Gestion de projet (pilotage technique, organisation des travaux, gestion
                                                du planning, fournisseurs internes et externes, communication, gestion des
                                                changements, gestion des plannings et budget ...)
                                                - Détection des variances par rapport au planning, identification des
                                                risques, proposition de solutions palliatives et remontée des risques à la
                                                hiérarchie
                                                - Définition & suivi des actions face aux problèmes / points à résoudre
                                                Compétences :

                                                ▪ Avoir une large vision technique ainsi que des connaissances de tous les
                                                composants infrastructure actuels (cloud hybride, Iaas, PaaS, SaaS, briques
                                                azure, composants réseaux, sécurité, …)
                                                ▪ Maîtrise de Microsoft Azure et 365 – Certifications recommandés
                                                ▪ Connaissance et maîtrise des Micro/Web Services
                                                ▪ VMWare
                                                ▪ Windows Server (tous services : GPO, DNS, DHCP, Hybridation, …)
                                                ▪ SQL Server
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="sidebar box">
                <div class="sub_title">
                    <h2 class="sub_page_title">Consultants</h2>
                </div>
                <form action="" method="">
                    <div class="cv_card">
                        <div class="link">
                            <div class="_cv">
                                <a href="" class="cv">Yahia_Ait_Bouachrine.pdf</a>
                                <input type="checkbox" class="checked" value="">
                            </div>
                        </div>
                    </div>

                    <div class="cv_card">
                        <div class="link">
                            <div class="_cv">
                                <a href="" class="cv">Yahia_Ait_Bouachrine.pdf</a>
                                <input type="checkbox" class="checked" value="">
                            </div>
                        </div>
                    </div>

                    <input type="submit" value="Enregistrer" name="" id="">
                </form>
            </div>
        </div>
    </div>
    <footer></footer>
</template>
  
<script>
export default {
    name: 'MissionAttribuer',
};
</script>
  
<style scoped>
* {
    font-family: Helvetica, sans-serif;
}

.container {
    margin-top: 100px !important;
    margin: 0 auto;
}

.in__container {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

.container .title {
    width: 90%;
    margin: 0 auto;
}
.sub_page_title {
    color: #595959;
    font-weight: 600;
  }

  .sub_title {
    padding: 10px 30px;
  }
  
.in__container .form {
    width: 57%;
}

.page_title,
.card_title {
    font-weight: bold;
}

.in__container .sidebar {
    width: 40%;
    margin: 15px;
    padding: 15px 0px;
    border: 1px solid #e9e0e0;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}

.sidebar form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.in__container .sidebar a {
    /* padding: 15px 10px;
    background: #146C94;
    color: #fff;
    border-radius: 5px;
    font-size: 18px;
    text-decoration: none; */
}

.in__container .sidebar a:hover {
    /* background: #19A7CE; */
    transition: 0.3s;
}

.cardavalider {
    padding: 30px 20px;
    border: 1px solid #e9e0e0;
    border-radius: 10px;
    margin: 15px 0;
}

.card_body {
    display: flex;
    align-items: center;
}

.desc h3 {
    font-weight: bold;
    margin: 15px 0;
}

.card_summary {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
}

.card_summary svg {
    font-size: 20px;
}

.card_content {
    width: 95%;
}

._cv {
    display: flex;
    align-items: center;
    justify-content: center;
}

._cv input[type="checkbox"]{
    margin: 0 0 0 30px;
    width: 20px;
    height: 20px;
}


.form-body h1 {
    text-align: center;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.cv_card {
    display: flex;
    align-items: center;
    width: 90%;
    height: 70px;
    padding: 10px 5px;
    margin: 0 auto;
    margin-bottom: 15px !important;
    text-align: center;
    border: 1px solid #e9e0e0;
    border-radius: 10px;
    box-shadow: 0px 3px 5px -2px rgba(0, 0, 0, 0.5);
}

.cv_card .cv {
    text-decoration: none;
    font-weight: bold;
    color: #000;
}

.link {
    width: 100%;
}

.actions {
    width: 30%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.box {
    box-shadow: 0px 3px 5px -2px rgba(0, 0, 0, 0.5);
}

/* Add your media queries here */
@media only screen and (max-width: 900px) {
    .cv_card {
        width: 90%;
    }

    .link {
        width: 100%;
    }

    .actions {
        width: 20%;
    }
}
input[type="submit"] {
    text-align: center;
    padding: 10px 50px;
    font-size: 17px;
    font-weight: bold;
    background: #D9D9D9;
    color: #595959;
    border: none;
}

input[type="submit"]:hover {
    background-color: #595959;
    color: #fff;
    transition: 0.3s;

}

@media only screen and (max-width: 700px) {
    .row {
        margin: 0;
        flex-direction: column;
    }
    .title {
        margin-top: 15px;
    }

    .in__container {
        flex-direction: column;
        align-items: center;
    }

    .in__container .form {
        width: 100%;
    }

    .in__container .sidebar {
        width: 100%;
    }

    .card_body {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .card_actions {
        width: 50%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .card_actions svg {
        font-size: 35px;
    }

    .card_body .card_summary {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding: 5px 0;
    }

    .card_summary a {
        margin: 5px 0;
    }
}
</style>
  