<template>
  <Header />
  <div class="main-container">
    <Sidebar />
    <main class="content">
      <div class="content-section">
        <Card />
      </div>
    </main>
  </div>
</template>
  
<script>

import Header from '../../components/Header.vue';
import Sidebar from '../../components/Sidebar.vue';
import Card from '../../components/Card.vue';

export default {
  components: {
    Header,
    Card,
    Sidebar,

  },
}
</script>
  
<style scoped>
.main-container {
  display: flex;
}

.content {
  flex: 1;
  /* padding: 20px; */
}


.content-section {
  /* margin: 20px;
  padding: 20px; */
  /* border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  height: 100vh;
}

@media (max-width: 768px) {
  .main-container {
    flex-direction: column;
  }

  .content {
    padding: 20px;
  }
}
</style>