<template>
    <Header />
    <div class="main-container">
        <Sidebar />
        <main class="content">
            <div class="content-section">
                <h1 class="page_title">Valider la mission</h1>
                <div class="container">
                    <div class="card">
                        <h1>{{ mission.nom_mission }}</h1>
                        <div class="details">
                            <div>
                                <a><i class="fa-regular fa-clock"></i> Publié le : {{ formatMissionDate(mission.created_at)
                                }}</a>
                            </div>
                            <div>
                                <a><i class="fa-solid fa-location-dot"></i> {{ mission.ville }}</a>
                            </div>

                            <div>
                                <a><i class="fa-solid fa-hourglass-start"></i> Date debut : {{
                                    formatMissionDate(mission.date_debut_ms)
                                }}</a>
                            </div>
                            <div>
                                <a><i class="fa-solid fa-hourglass-end"></i> Date fin : {{
                                    formatMissionDate(mission.date_fin) }}</a>
                            </div>
                            <div>
                                <a><i class="fa-regular fa-calendar-days"></i> Durée : {{ mission.duree }} mois</a>
                            </div>
                        </div>
                        <div class="card_description">
                            <p class="desc">{{ mission.description_mission }}</p>
                            <p class="comp">Competences: {{ mission.competences_requises }}</p>
                        </div>
                        <hr>
                        <div class="btn-container">
                            <button @click="accepter" class="btn accept">Accepter</button>
                            <button @click="refuser" class="btn refuse">Refuser</button>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>


<script>
import axios from 'axios';
import Header from '../../components/Header.vue';
import Sidebar from '../../components/Sidebar.vue';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';


export default {
    name: 'MissionDetails',
    components: {
        Header,
        Sidebar
    },
    data() {
        return {
            mission: {},
        };
    },
    methods: {
        formatMissionDate(date) {
            return moment(date).format('DD-MM-YYYY');
        },
        fetchMission() {
            axios.get(`http://missions-freelance-industrie.fr/missions/${this.$route.params.id}`)
                .then(response => {
                    this.mission = response.data.data;
                })
                .catch(error => {
                    console.error(error);
                    this.errorMsg = "Failed to fetch mission details.";
                });
        },
        async accepter() {
            try {
                const response = await axios.post(`http://missions-freelance-industrie.fr/missions/${this.mission._id}/accept`);
                if (response.data && response.data.message) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Accepter!',
                        text: response.data.message,
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.$router.push('/MissionsValider');
                        }
                    });
                }
            } catch (error) {
                console.error('Error accepting the mission:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'erreur.'
                });
            }
        },
        async refuser() {
            try {
                const result = await Swal.fire({
                    title: 'Êtes-vous sûr?',
                    text: "Voulez-vous vraiment refuser?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Oui',
                    cancelButtonText: 'Annuler'
                });

                if (result.isConfirmed) {
                    const response = await axios.post(`http://missions-freelance-industrie.fr/missions/${this.mission._id}/refuse`);
                    if (response.data && response.data.message) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Refusé!',
                            text: response.data.message,
                            confirmButtonText: 'OK'
                        }).then(() => {
                            this.$router.push('/MissionsValider');
                        });
                    }
                }
            } catch (error) {
                console.error('Error refusing the mission:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'erreur.'
                });
            }
        }
    },
    created() {
        this.fetchMission();
    }
};
</script>


<style scoped>
* {
    font-family: Helvetica, sans-serif;
}

.page_title {
    text-align: center;
    padding: 20px 0;
}

.main-container {
    display: flex;
}

.content {
    flex: 1;
    /* padding: 20px; */
}

.container {
    width: 90%;
    margin: 10% auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;

    justify-content: space-around;
}

.card {
    width: 80%;
    border-radius: 15px;
    border: 1px solid #BFBFBF;
    background-color: white;
    box-shadow: 00px 2px 10px 0px #aaaaaa;
    padding: 30px 20px;
}

.card_description .desc,
.comp {
    line-height: 2em;
}

.card .postuler {
    background-color: #19A7CE;
    border-radius: 8px;
    color: #fff;
    font-size: 20px;
    text-decoration: none;
    padding: 15px 25px;

}

.card .postuler:hover {
    background-color: #0d7bae;
}

.card hr {
    margin: 20px 0;
}

.card .details {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
    color: #333;
    margin: 15px 0;
}

.card .details i {
    font-size: 20px;
    color: #19a7ce;
}

.card .competences {
    margin: 20px;

}

.btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.btn {
    padding: 10px 20px;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
}

.accept {
    background-color: #23af5f;
    color: white;
    border: none;
    padding: 15px 30px;
    font-size: 15px;
}

.refuse {
    background-color: #de3221;
    color: white;
    border: none;
    padding: 15px 30px;
    font-size: 15px;
}

.btn:hover {
    opacity: 0.9;
    transition: 0.5s;
}

@media only screen and (max-width: 900px) {
    .card .details {
        display: flex;
        flex-direction: column;
        row-gap: 0.5em;
    }
}</style>


