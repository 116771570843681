<template>
    <div class="candidate-popup" v-if="candidate">
        <div class="popup-content">
            <div class="head">
                <h2>Détails de Candidat</h2>
                <a @click="closePopup" class="close"><i class="fa-regular fa-circle-xmark"></i></a>
            </div>
            <div class="body">
                <table>
                    <tr>
                        <td class="t1">Nom:</td>
                        <td>{{ candidate.nom_candidat }}</td>
                    </tr>
                    <tr>
                        <td class="t1">Prénom:</td>
                        <td>{{ candidate.prenom_candidat }}</td>
                    </tr>
                    <tr>
                        <td class="t1">Date Naissance:</td>
                        <td>{{ formatMissionDate(candidate.date_naissance) }}</td>
                    </tr>
                    <tr>
                        <td class="t1">Email:</td>
                        <td><a>{{ candidate.email_candidat }}</a></td>
                    </tr>
                    <tr>
                        <td class="t1">Numéro de téléphone:</td>
                        <td>{{ candidate.tel_candidat }}</td>
                    </tr>
                    <tr>
                        <td class="t1">Taux journalier moyen:</td>
                        <td>{{ candidate.TJM }} €</td>
                    </tr>
                    <tr>
                        <td class="t1">Date disponibilité:</td>
                        <td>{{ formatMissionDate(candidate.disponibilte) }}</td>
                    </tr>
                    <tr>
                        <td class="t1">CV:</td>
                        <td><a :href="getCvDownloadUrl(candidate.cv_candidat)" target="_blank" class="cv">Consulter le CV</a></td>
                    </tr>
                </table>
            </div>

        </div>
    </div>
</template>
  
<script>
import moment from 'moment-timezone';

export default {
    props: {
        candidate: Object,
        getCvDownloadUrl: Function,
        getFileName: Function,
    },
    methods: {
        formatMissionDate(date) {
            return moment(date).format('DD-MM-YYYY');
        },
        closePopup() {
            this.$emit("close");
        },
    },
};
</script>
  
  
<style scoped>
.head {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.head h2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95%;
}

.close {
    color: #c13636;
    font-size: 30px;
}

.cv {
    color: #000;
    text-decoration: underline !important;
}

.cv:hover {
    color: #8a8a8a;
    transition: 0.5s;
}
.body {
    font-size: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 1em;
    padding: 30px 0;
}
.body i {
    text-align: center;
    padding: 10px 0;
    font-size: 3em;
    color: #4d565e;
}
.body table tr {
    line-height: 2em;
}
.body table .t1 {
    font-weight: bold;
}
.candidate-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.popup-content {
    width: 30%;
    background-color: white;
    padding: 25px 25px 50px;
    border-radius: 5px;
}

button {
    margin-top: 10px;
}
</style>
  