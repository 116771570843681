<template>
  <footer class="footer">

    <div class="footer-content">
      <div class="footer-text-container">
        <div class="footer-text logo">
          <span class="logo-text">LOGO</span>
        </div>
        <div>
          <p class="footer-text">Qui sommes nous ?</p>
          <ul class="footer-list">
            <li>Qui sommes nous ?</li>
            <li>Nos partenaires</li>
            <li>Contact</li>
          </ul>
        </div>
        <div>
          <p class="footer-text">Liens rapides</p>
          <ul class="footer-list">
            <li>Trouver votre freelance</li>
            <li>Déposer une offre de mission</li>
          </ul>
        </div>
        <div>
          <p class="footer-text">Informations Légales</p>
          <ul class="footer-list">
            <li>Mentions légales</li>
            <li>Conditions Générales d'Utilisation</li>
          </ul>
        </div>
      </div>
    </div>
    <p class="copyright">© Copyright 2023 | Nom de Site web</p>
  </footer>
</template>

<script>
export default {
  // Component logic
}
</script>

<style scoped>
.footer {
  background-color: #146C94;
  color: #fff;
  padding: 50px 0px 20px;
  width: 100%;
  margin-top: 50px;
  text-align: center;
}

.footer-content {
  display: flex;
  justify-content: center;
  width: 80%;
  margin: 0 auto;
}

.logo .logo-text {
  color: #fff;
  position: absolute;
  font-size: 21px;
  text-align: center;
}

.footer-text-container {
  display: flex;
  align-items: flex-start;
  width: 100%;
  justify-content: space-between;
}

.footer-text {
  color: #FFF;
  text-align: initial;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
  margin-bottom: 10px;
}

.footer-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.footer-list li {
  color: #FFF;
  text-align: initial;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

.copyright {
  padding-top: 50px;
}

</style>
