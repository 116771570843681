<template>
  <Header />
  <div class="container">
    <h1>Profile d’admin</h1>
    <div class="dash">
      <div class="user">
        <i class="fa-solid fa-circle-user"></i>
        <p>Mr. {{ getadmin.nom_admin }} {{ getadmin.prenom_admin }}</p>
      </div>
      <div class="details">
        <h2>Détails</h2>

        <div class="details-container">
          <div class="left_details">
            <label>Nom</label>
            <input type="text" name="text" id="text" class="inpt1" 
                   :placeholder="getadmin.nom_admin" v-model="updatedNomAdmin">
            <label>Prénom</label>
            <input type="text" name="prenom" id="prenom" class="inpt1" 
                   :placeholder="getadmin.prenom_admin" v-model="updatedPrenomAdmin">
            <label>Email</label>
            <input type="email" name="email" id="email" class="inpt1" 
                   :placeholder="getadmin.email_admin" v-model="updatedEmailAdmin">
          </div>

          <div class="right_details">
            <label>Mot de passe</label>
            <input type="password" name="password" id="password" class="inpt1" placeholder="********" >
            <label>Nouveau Mot de passe</label>
            <input type="password" name="newPassword" id="newPassword" class="inpt1" placeholder="********" 
                   v-model="updatedPasswordAdmin">
          </div>
        </div>
        
        <div class="btn">
          <button class="modifier" @click="updateAdmin">Modifier</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '../../components/Header.vue'
import { mapGetters } from 'vuex'
import Swal from 'sweetalert2'

export default {
  components: {
    Header,
  },
  data() {
    return {
        updatedNomAdmin: '',
        updatedPrenomAdmin: '',
        updatedEmailAdmin: '',
        updatedPasswordAdmin: '',
    };
  },
  computed: {
    ...mapGetters([
      'getadmin',
    ])
  },
  methods: {
    async updateAdmin() {
        const dataToUpdate = {
            nom_admin: this.updatedNomAdmin || this.getadmin.nom_admin,
            prenom_admin: this.updatedPrenomAdmin || this.getadmin.prenom_admin,
            email_admin: this.updatedEmailAdmin || this.getadmin.email_admin,
            password_admin: this.updatedPasswordAdmin
        };

        try {
            const response = await fetch('http://missions-freelance-industrie.fr/admin/' + this.getadmin._id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(dataToUpdate)
            });

            const responseData = await response.json();

            if(response.status === 200) {
                Swal.fire({
                    title: 'Updated!',
                    text: 'Admin a été modifier.',
                    icon: 'success',
                    confirmButtonText: 'OK'
                });
            } else {
                Swal.fire({
                    title: 'Error!',
                    text: responseData.message,
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            }
        } catch (error) {
            Swal.fire({
                title: 'Error!',
                text: 'Erreur dans la modification de l\'admin.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
    }
  }
}
</script>

<style scoped>
.container {
  margin: 30px 0 !important;
}
.dash {
  display: flex;
  width: 80%;
  justify-content: space-between;
  margin-top: 2%;
  background: #fff;
  padding: 40px;
  border-radius: 10px;
  border: 1px solid #ebe5e5;
}

.user { 
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.5em;
  align-content: center;
  justify-content: center;
}

.user p {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5em;
}

.details {
  width: 70%;
}

.left_details {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.right_details {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.details .btn {
  padding: 20px 0;
  text-align: center;
}

.details-container {
  display: flex;
  margin-top: 20px;
  column-gap: 4em;
}

.details-container label {
  margin: 30px 0px 5px 0;
  color: #444;
  font-weight: 600;
}

.details-container input {
  height: 2.5em;
  border: none;
  border-radius: 6px;
  padding: 0 2px;
  margin-top: 5px;
  background: #eef7fe;
  outline: none;
}

.details-container .inpt1:focus {
  transition: 0.5s;
  border: 2px solid #ccd9f3;
}


.user i {
  font-size: 10em;
  color: #4e525a;
}

.modifier {
  background-color: rgb(4 104 177 / 95%);
    color: white;
    padding: 15px 60px;
    font-size: 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 700;
}

.modifier:hover {
  background-color: rgba(8, 66, 108, 0.95);
  transition: 0.5s;
}
</style>