<template>
    <div class="header">
        <div class="title">Consultant</div>
        <div class="menu">
            <router-link to="/dashboard">Dashboard</router-link>
            <router-link to="/">Annonces</router-link>
        </div>
        <div class="navbar-right">
            <a><img src="../assets/Ellipse.svg"></a>
            <div class="dropdown-container">
                <a @click.prevent="toggleDropdown"><i class="fa-solid fa-caret-down"></i></a>
                <div v-if="showDropdown" class="dropdown-menu">
                    <router-link to="/profileadmine" href="#"><i class="fa-solid fa-user"></i> Profile</router-link>
                    <a href="#" @click.prevent="logout"> <i class="fa-solid fa-right-from-bracket"></i>Deconnexion</a>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'Header',

    data() {
        return {
            showDropdown: false
        };
    },
    methods: {
        toggleDropdown() {
            this.showDropdown = !this.showDropdown;
        },
        logout() {
            if (localStorage.getItem('consultant_token')) {
                localStorage.removeItem('consultant_token');
                this.$router.push('/loginconsultant');
                // return;
            }

            if (localStorage.getItem('admin_token')) {
                localStorage.removeItem('admin_token');
                this.$router.push('/loginadmin');
                // return;
            }
            // this.$router.push('/dashboard');
        }
    }
}
</script>
  
<style scoped>
.header {
    padding: 20px 10px;
    background-color: #333;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header a {
    color: #fff;
}

.title {
    width: 15%;
    font-size: 20px;
}

.side {
    display: none;
}

.menu {
    width: 60%;
    display: flex;
    justify-content: center;
    -moz-column-gap: 5em;
    column-gap: 3em;
    font-size: 15px;
}

.navbar-right {
    /* display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 1em; */
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    -moz-column-gap: 1em;
    column-gap: 1em;
    text-align: right;
}

.navbar-right i {
    color: #f6f1f1;
    font-size: 25px;}

.navbar-right a:last-child {
    margin-right: 0;
}

.dropdown-container {
    position: relative;
    display: inline-block;
}

.dropdown-menu {
    display: none;
    padding: 5px 10px;
    position: absolute;
    top: 100%;
    right: 0px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 101;
    border-radius: 5px 5px 10px 10px;
}

.dropdown-container:hover .dropdown-menu {
    display: block;
}

.dropdown-menu a {
    color: black;
    padding: 10px 20px;
    display: flex;
    column-gap: 1em;
    justify-content: flex-start;
    flex-direction: row;
}

.dropdown-menu i {
    color: #333;
}

.navbar,
.navbar-right,
.dropdown-container {
    overflow: visible;
}


@media screen and (max-width: 850px) {
    .header{
        padding: 20px 10px;
    background-color: #333;
    color: white;
    display: flex;
    justify-content: space-around;
    align-items: center;
    }
    .title {
        display: none;
    }
}

</style>
  