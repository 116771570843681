<template>
    <Navbar />
    <div class="container">

        <div class="title">
            <h1 class="page_title">Mes Missions</h1>
        </div>
        <div class="in__container">
            <h2>Mes mission courantes</h2>
            <div v-for="mission in currentMissions" :key="mission._id" class="cardavalider box"
                @click="navigateToMissionDetails(mission._id)">
                <h2 class="card_title">{{ mission.nom_mission }}</h2>
                <div class="desc">
                    <div class="description_mission">
                        <h3>Description</h3>
                        <p>
                            {{ mission.description_mission }}
                        </p>
                    </div>

                    <!--
                    <div class="competences">
                    <h3>Compétences requises</h3>
                    <p>A{{ mission.competences_requises }}</p>
                  </div>
                -->
                </div>
                <div class="card_summary">
                    <a><i class="fa-regular fa-clock"></i> Publié le : {{ formatMissionDate(mission.created_at) }}</a>
                    <a><i class="fa-solid fa-location-dot"></i> {{ mission.ville }}</a>
                    <a><i class="fa-regular fa-calendar-days"></i> Durée : {{ mission.duree }} mois</a>
                    <a><i class="fa-solid fa-hourglass-start"></i> Démarage : {{ formatMissionDate(mission.date_debut_ms)
                    }}</a>
                </div>
            </div>
            <h2>Mes missions terminées</h2>
            <div v-for="mission in finishedMissions" :key="mission._id" class="cardavalider box"
                @click="navigateToMissionTermine(mission._id)">
                <h2 class="card_title">{{ mission.nom_mission }}</h2>
                <div class="desc">
                    <div class="description_mission">
                        <h3>Description</h3>
                        <p>
                            {{ mission.description_mission }}
                        </p>
                    </div>
                </div>
                <div class="card_summary">
                    <a><i class="fa-regular fa-clock"></i> Publié le : {{ formatMissionDate(mission.created_at) }}</a>
                    <a><i class="fa-solid fa-location-dot"></i> {{ mission.ville }}</a>
                    <a><i class="fa-regular fa-calendar-days"></i> Durée : {{ mission.duree }} mois</a>
                    <a><i class="fa-solid fa-hourglass-start"></i> Démarage : {{ formatMissionDate(mission.date_debut_ms)
                    }}</a>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import Navbar from '../../components/Pages-admine/Navbar.vue';
import { mapGetters } from 'vuex';
import axios from 'axios';
import moment from 'moment-timezone';

export default {
    components: {
        Navbar,
    },
    data() {
        return {
            currentMissions: [],
            finishedMissions: []
        };
    },
    computed: {
        ...mapGetters([
            'getconsultant',
        ])
    },
    methods: {
        formatMissionDate(date) {
            return moment(date).format('DD-MM-YYYY');
        },
        navigateToMissionDetails(missionId) {
            this.$router.push(`/MissionsDetail/${missionId}`);
        },
        navigateToMissionTermine(missionId) {
            this.$router.push(`/missionterminerdetails/${missionId}`);
        },
        async fetchCurrentMission() {
            try {
                const consultantId = this.getconsultant._id;
                const response = await axios.get(`http://missions-freelance-industrie.fr/missions/consultant/${consultantId}`);

                if (response.data && response.data.data) {
                    this.currentMissions = response.data.data;
                }
            } catch (error) {
                console.error('Error fetching current missions:', error);
            }
        },
        async fetchFinishedMissions() {
            try {
                const consultantId = this.getconsultant._id;
                const response = await axios.get(`http://missions-freelance-industrie.fr/missions/finished/${consultantId}`);

                if (response.data && response.data.data) {
                    this.finishedMissions = response.data.data;
                }
            } catch (error) {
                console.error('Error fetching finished missions:', error);
            }
        }
    },
    mounted() {
        this.fetchCurrentMission();
        this.fetchFinishedMissions();
    }
}
</script>


<style scoped>
.container {
    width: 90%;
    margin-top: 30px !important;
    margin: 0 auto;
}

.page_title {
    font-weight: bold;
    text-align: center;
    margin-bottom: 50px;
}

.in__container {
    display: flex;
    flex-direction: column;
    gap: 2em;
    width: 65%;
}

.cardavalider {
    padding: 30px 20px;
    border-radius: 10px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s, box-shadow 0.3s;
}

.cardavalider:hover {
    transform: translateY(-5px);
    box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.1);
}

.card_title {
    font-weight: bold;
    margin-bottom: 20px;
    font-size: 1.5rem;
}

.card_description {
    margin-bottom: 20px;
}

.desc h3 {
    font-weight: bold;
    margin: 15px 0;
}

.desc .competences,
.description_mission {
    margin: 30px 0;
}

.card_summary {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    font-size: 0.9rem;
    color: grey;
    justify-content: space-between;
}

.card_summary i {
    font-size: 20px;
    color: #093c89;
}

.box {
    background: #fff !important;
}

@media only screen and (max-width: 600px) {
    .container {
        width: 100%;
    }

    .in__container {
        padding: 0 10px;
    }
}
</style>