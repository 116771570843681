<template>
    <Navbar />
    <div class="container">
        <div class="espace">
            <div class="image-title">
                <img src="../../assets/Registered.png"> <h2>Ajouter un consultant</h2>
            </div>
           <form @submit.prevent="handleSubmit">
                <label for="nom">Nom:</label>
                <input type="text" id="nom" name="nom" v-model="nom" placeholder="Saisir le nom">
                <label style="margin-right:290px;" for="email">Email:</label>
                <input type="text" id="email" v-model="email" name="email" placeholder="Saisir la email">
                <label style="margin-right:290px;" for="prénom">Password:</label>
                <input type="text" id="prenom" v-model="password" name="prenom" placeholder="Saisir le password">
                <label style="margin-right:320px;" for="cv">CV:</label>
                <input type="file" @change="handleFileUpload" style="border-bottom: 1px solid #000; background: #F6F6F6;width: 400px; height: 35px;margin-left:50px;"
                 id="cv" name="cv" placeholder="CV">
                <button class="modifier">Ajouter</button>
           </form>
        </div>
    </div>
</template>

<script>
import Navbar from '../../components/Pages-admine/Navbar.vue'
import axios from 'axios';


export default {
    components: {
    Navbar,
  },
  data() {
    return {
      nom: '',
      email: '',
      password: '',
      cv: '',
      id_mission: '',
    };
  },
  methods: {
    handleFileUpload(event) {
    this.cv = event.target.files[0];
    },
    handleSubmit() {
      let formData = new FormData();
      formData.append('nom_consultant', this.nom);
      formData.append('email_consultant', this.email);
      formData.append('password_consultant', this.password);
      formData.append('CV_consultant', this.cv);
      formData.append('id_mission', this.id_mission);
      axios
        .post('http://missions-freelance-industrie.fr/consultant', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(response => {
          window.location.href = '/dashboard';
        })
        .catch(error => {
        });
    },
    
  },
}
</script>

<style scoped>
body {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.espace {
  width: 700px;
  height: 700px;
  border-radius: 10px;
  border: 1px solid #E9E0E0;
  background: #FFF;
  box-shadow: 0px 4px 8px -3px rgba(0, 0, 0, 0.25);
  margin-top: 50px;
  display: flex; /* Ajout de display flex */
  flex-direction: column; /* Aligner les éléments les uns en dessous des autres */
  align-items: center; /* Centrer horizontalement les éléments */
}
.image-title {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.espace img {
  margin-right: 10px;
  margin-top: 8px;
}

.espace h2 {
  margin-top: 20px;
}

/* Style pour le formulaire */
form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  margin-right: 120px;
}

label {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 15px;
  margin-right: 300px;
}

input {
  padding: 8px;
  width: 352px;
  height: 30px;
  border-radius: 0px;
  border: none; /* Remove any existing border */
  border-bottom: 1px solid black;
  background: white;
  margin-bottom: 50px;
}
.modifier{
    width: 450px;
    height: 39px;
    border-radius: 5px;
    border: none;
    background: rgba(20, 108, 148, 0.95);
    color: white;
    margin-left: 80px;
    margin-top: 20px;
}
</style>