<template>
  <Header />
  <div class="main-container">
    <Sidebar />
    <main class="content">
      <div class="content-section">
        <MissionDetails />
      </div>
    </main>
  </div>
</template>
  
<script>
import Header from '../../components/Header.vue';
import Sidebar from '../../components/Sidebar.vue';
import MissionDetails from '../../components/MissionDetails.vue';

export default {
  components: {
    Header,
    Sidebar,
    MissionDetails,

  },
}
</script>
  
<style scoped>
.main-container {
  display: flex;
}

.content {
  flex: 1;
}

@media (max-width: 768px) {
  .main-container {
    flex-direction: column;
  }

  .content {
    padding: 20px;
  }
}
.content-section {
  min-height: 100vh;
}
</style>